<template>
  <b-overlay :show="studentEvents.loading || state.saving" :opacity="1" bg-color="white" style="min-height: 100px">
    <template #overlay>
      <div class="d-flex flex-column align-items-center gap-1">
        <b-spinner/>
        <div>{{ state.saving ? 'Saving' : 'Loading' }}</div>
      </div>
    </template>
    <template #default>
      <b-card class="card-event">
        <b-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              Assigned Students <small>({{ studentEvents.items.length }})</small>
            </div>
            <div>
              <b-button size="sm" variant="link" class="btn-icon" @click="ui.grouped = !ui.grouped">
                <b-icon :icon="ui.grouped ? 'diagram3-fill' : 'diagram3'" style="width: 15px; height: 15px"/>
              </b-button>
              <b-button size="sm" variant="link" class="btn-icon" @click="ui.sorting.show = !ui.sorting.show">
                <b-icon-sort-down style="width: 14px; height: 14px"/>
              </b-button>
              <b-button size="sm" variant="link" class="btn-icon" @click="ui.filters.show = !ui.filters.show">
                <b-icon :icon="isFiltered ? 'funnel-fill' : 'funnel'" style="width: 14px; height: 14px"/>
              </b-button>
            </div>
          </div>
          <div class="pt-1">
            <b-form-group>
              <b-input v-model="search" type="search" placeholder="Search..."/>
            </b-form-group>
          </div>
        </b-card-title>

        <b-row>
          <b-col cols="12">
            <b-card v-if="ui.filters.show" bg-variant="light-primary" body-class="px-1" class="mb-2">
              <div class="font-weight-bold font-small-3 d-flex align-self-center justify-content-between">
                <span v-b-toggle="'assigned-students-filters-collapse'">Filters</span>
                <div class="d-flex align-items-center">
                  <b-badge v-if="isFiltered" title="Active Filters" variant="primary" pill class="mr-1">{{ activeFilters.length }}</b-badge>
                  <b-icon-x v-else style="width: 15px; height: 15px;"
                            class="cursor-pointer mr-1"
                            @click="ui.filters.show = false"/>
                  <b-icon-chevron-down style="width: 14px; height: 14px;"
                                       class="cursor-pointer"
                                       :class="ui.filters.expanded ? 'rotate-180' : 'collapsed'"
                                       aria-controls="students-tab-filters-collapse"
                                       :aria-expanded="ui.filters.expanded ? 'true' : 'false'"
                                       @click="ui.filters.expanded = !ui.filters.expanded "/>
                </div>
              </div>
              <b-collapse id="assigned-students-filters-collapse" v-model="ui.filters.expanded">
                <b-row class="mt-1">
                  <b-col>
                    <b-form-group label="Ensemble" label-for="filter-ensemble">
                      <v-select v-model="filters.ensemble"
                                :loading="studentEvents.loading"
                                input-id="filter-ensemble"
                                placeholder="Ensemble"
                                :options="ensembles.items" label="name"
                                :reduce="option => option.id"
                                :select-on-tab="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="w-100 font-small-3"/>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Instrument" label-for="filter-instruments">
                      <v-select v-model="filters.instrument"
                                :loading="studentEvents.loading"
                                input-id="filter-instruments"
                                placeholder="Instrument"
                                :options="instruments.items" label="name"
                                :reduce="option => option.id"
                                :select-on-tab="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="w-100 font-small-3"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card v-if="ui.sorting.show" bg-variant="light-primary" body-class="px-1" class="mb-2">
              <div class="font-weight-bold font-small-3 d-flex align-self-center justify-content-between">
                <span v-b-toggle="'assigned-students-sorting-collapse'">Sorting</span>
                <div class="d-flex align-items-center">
                  <b-badge v-if="sorting.by.length" title="Active Sorting" variant="primary" pill class="mr-1">{{ sorting.by.length }}</b-badge>
                  <b-icon-x v-else style="width: 15px; height: 15px;"
                            class="cursor-pointer mr-1"
                            @click="ui.sorting.show = false"/>
                  <b-icon-chevron-down style="width: 14px; height: 14px;"
                                       class="cursor-pointer"
                                       :class="ui.sorting.expanded ? 'rotate-180' : 'collapsed'"
                                       aria-controls="students-tab-sorting-collapse"
                                       :aria-expanded="ui.sorting.expanded ? 'true' : 'false'"
                                       @click="ui.sorting.expanded = !ui.sorting.expanded "/>
                </div>
              </div>
              <b-collapse id="assigned-students-sorting-collapse" v-model="ui.sorting.expanded">
                <b-form-group label="Sort By" label-for="sorting-by" class="mt-1">
                  <sort-input v-model="sorting"/>
                </b-form-group>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>

        <b-list-group v-for="(group, index) in groupedAssignedStudents" :key="index">
          <b-list-group-item tag="li" :class="['align-items-center p-50 rounded bg-light-primary', index === 0 ? 'mt-0' : 'mt-1']">
            <div class="d-flex justify-content-between align-items-center">
              <div v-b-toggle="`collapse-assigned-group-${group.id}`" class="py-1 pl-75 font-weight-bold d-flex flex-row w-100 align-items-center">
                <div>
                  {{ group.name }} <small>({{ group.studentEvents.length }})</small>
                </div>
              </div>
              <b-dropdown v-if="state.editing" :id="`collapse-assigned-group-${group.id}`"
                          variant="link"
                          no-caret dropright title="Options"
                          :right="$store.state.appConfig.isRTL"
                          :toggle-class="['px-50']">
                <template #button-content>
                  <b-icon-three-dots-vertical style="width: 14px; height: 14px"/>
                </template>
                <can do="update" on="events-buses">
                  <b-dropdown-item :id="`${group.id}-dropdown-item-unassignAll`" :disabled="state.saving" @click.stop="unassignStudentEvents(group.studentEvents)">
                    <feather-icon icon="MoveIcon"/>
                    <span class="align-middle ml-50">Unassign All Students</span>
                  </b-dropdown-item>
                </can>
              </b-dropdown>
            </div>
            <b-collapse :id="`collapse-assigned-group-${group.id}`">
              <vue-perfect-scrollbar :settings="scrollbarSettings" class="ps-assigned-students">
              <b-list-group class="rounded bg-transparent-off p-50">
                <b-list-group-item v-for="(studentEvent, index) in group.studentEvents"
                                   :id="studentEvent.id" :key="studentEvent.id"
                                   tag="li"
                                   :action="false"
                                   :class="['rounded border-0 shadow p-2', index === 0 ? 'mt-0' : 'mt-1']">
                  <b-row>
                    <b-col cols="auto" align-self="start">
                      <b-avatar :variant="`light-primary`" size="44px" :badge-variant="getSexBadgeVariant(studentEvent.student)">
                        <font-awesome-icon :icon="getSexIcon(studentEvent.student)" style="width: 22px; height: 22px" />
                        <template #badge>
                          {{ getSexBadge(studentEvent.student) }}
                        </template>
                      </b-avatar>
                    </b-col>
                    <b-col align-self="center">
                      <div class="truncate">
                        <h5 class="mb-25 font-weight-bolder">
                          {{ studentEvent.student.name.first }} {{ studentEvent.student.name.last }}
                        </h5>
                        <div :data="application = getApplicationWithSelection(studentEvent.student.applications)" class="font-small-3">
                          {{ application.selection.ensemble.name }} - {{ application.instrument.name }}
                        </div>
                        <div class="font-small-3">
                          {{ getHotelName(studentEvent.hotelId) }} - Room {{ studentEvent.room }}
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" align-self="center">
                      <div v-if="state.editing">
                        <b-button v-if="isPending(studentEvent)"
                                  :id="`bus-student-${studentEvent.student.id}-status`"
                                  size="sm" variant="link"
                                  :disabled="state.saving || overCapacity"
                                  class="btn-icon btn-icon rounded-circle" @click="assignStudentEvent(studentEvent)">
                          <b-icon-exclamation-circle-fill variant="danger" style="width: 14px; height: 14px"/>
                          <b-tooltip :target="`bus-student-${studentEvent.studentId}-status`" triggers="hover" variant="danger">Unsaved</b-tooltip>
                        </b-button>

                        <b-button :id="`bus-student-${studentEvent.studentId}-unassign`"
                                  size="sm" variant="link"
                                  :disabled="state.saving"
                                  class="btn-icon btn-icon rounded-circle"
                                  @click="unassignStudentEvent(studentEvent)">
                          <b-icon-x-circle style="width: 14px; height: 14px"/>
                          <b-tooltip :target="`bus-student-${studentEvent.studentId}-unassign`" triggers="hover" variant="primary">Unassign</b-tooltip>
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </vue-perfect-scrollbar>
            </b-collapse>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </template>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select';
import SortInput from '@/components/SortInput.vue';
import events from '@/mixins/event.mixin';
import notify from '@/mixins/notify.mixin';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import _ from 'lodash';
import Fuse from 'fuse.js';

export default {
  name: 'AssignedStudents',
  components: {
    SortInput,
    vSelect,
    VuePerfectScrollbar
  },
  mixins: [ events, notify ],
  props: {
    selected: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      required: true
    },
    hotels: {
      type: Object,
      required: true
    },
    instruments: {
      type: Object,
      required: true
    },
    ensembles: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      search: null,
      filters: {
        instrument: null,
        ensemble: null
      },
      sorting: {
        by: ['student.applications.items.instrument.name', 'student.name.last', 'student.name.first'],
        desc: false,
        drag: false,
        options: [
          { label: 'First Name', value: 'student.name.first'},
          { label: 'Last Name', value: 'student.name.last'},
          { label: 'Instrument', value: 'student.applications.items.instrument.name'},
          { label: 'Ensemble', value: 'student.applications.items.selection.ensemble.name'},
        ]
      },
      ui: {
        filters: {
          show: false,
          expanded: false
        },
        sorting: {
          show: false,
          expanded: false
        },
        grouped: false
      },
    }
  },
  computed: {
    bus() {
      return this.selected?.bus
    },
    studentEvents() {
      return this.selected?.bus?.studentEvents
    },
    overCapacity() {
      return this.selected?.bus?.studentEvents?.items?.length > this.selected?.bus?.capacity
    },
    assignedStudents() {
      const { items } = this.studentEvents
      const fuse = new Fuse(items, {

        keys: [
          'student.id',
          'student.name.first',
          'student.name.last',
          'student.applications.items.instrument.id',
          'student.applications.items.selection.ensemble.id',
        ]
      })

      const query = { $and: [ ] }
      if(this.filters.ensemble) {
        query.$and.push({ 'student.applications.items.selection.ensemble.id': this.filters.ensemble })
      }
      if(this.filters.instrument) {
        query.$and.push({ 'student.applications.items.instrument.id': this.filters.instrument })
      }
      if(this.search) {
        query.$and.push({
          $or: [
            { 'student.id': `'${this.search}` },
            { 'student.name.first': `'${this.search}` },
            { 'student.name.last': `'${this.search}` },
          ]
        })
      }

      let searchedItems;
      if(query.$and.length) { searchedItems = fuse.search(query).map(({ item }) => item) }
      else { searchedItems = items }
      if (this.sorting?.by?.length) {
        return this.sortItems(this.sorting, searchedItems);
      }
      return searchedItems;
    },
    groupedAssignedStudents() {
      if(!this.ui.grouped) {
        return [{ name: 'All', studentEvents: this.assignedStudents }];
      }

      // First, create a map of all ensembles with empty student arrays
      const ensembleMap = this.studentEvents.items.reduce((acc, studentEvent) => {
        const application = this.getApplicationWithSelection(studentEvent.student.applications)
        acc[application?.selection?.ensemble?.id] = {
          id: application?.selection?.ensemble?.id,
          name: application?.selection?.ensemble?.name,
          studentEvents: []
        };
        return acc;
      }, {});

      // Then, populate the student arrays for each ensemble
      this.assignedStudents.forEach((studentEvent) => {
        const application = this.getApplicationWithSelection(studentEvent.student.applications)
        const ensembleId = application?.selection?.ensemble?.id;
        ensembleMap[ensembleId].studentEvents.push(studentEvent);
      });

      // Finally, sort and return the ensembles as an array
      return Object.values(ensembleMap).sort((a, b) => a?.name?.localeCompare(b?.name)).filter(ensemble => ensemble.id);
    },
    isFiltered() {
      return this.search || (this.filters && Object.values(this.filters).some(f => f !== null));
    },
    activeFilters() {
      return Object.values(this.filters).filter(f => f !== null);
    },
  },
  methods: {
    assignStudentEvent(studentEvent) {
      this.$emit('assign:studentEvent', studentEvent);
    },
    unassignStudentEvent(studentEvent) {
      this.$emit('unassign:studentEvent', studentEvent);
    },
    unassignStudentEvents(studentEvents) {
      this.$emit('unassign:studentEvents', studentEvents);
    },
    isPending(studentEvent) {
      return studentEvent.status === 'pending'
    },
    getHotelName(hotelId) {
      const hotel = this.hotels.items.find(h => h.id === hotelId)
      if(hotel) return hotel.name
      return 'No Hotel'
    },

  }
}
</script>

<style scoped lang="scss">
.ps-assigned-students {
  max-height: 60vh;

  .list-group-item:hover {
    background-color: var(--white);
    cursor: default;
  }

  .list-group:empty,
  .list-group > div:empty {
    background-color: var(--white);
  }

  .list-group:empty:before,
  .list-group > div:empty:before {
    content: 'No Students Associated With This Bus...';
  }
}
</style>

<template>
  <b-overlay :show="students.loading" :opacity="1" bg-color="white" style="min-height: 100px">
    <b-card class="card-event">
      <b-card-title>
        <div class="d-flex justify-content-between align-items-center">
          <div>
            Unassigned Students <small>({{ unassignedStudents.length }})</small>
          </div>
          <div>
            <b-button size="sm" variant="link" class="btn-icon" @click="ui.sorting.show = !ui.sorting.show">
              <b-icon-sort-down/>
            </b-button>
            <b-button size="sm" variant="link" class="btn-icon" @click="ui.filters.show = !ui.filters.show">
              <b-icon :icon="isFiltered ? 'funnel-fill' : 'funnel'"/>
            </b-button>
          </div>
        </div>
        <div class="pt-1">
          <b-form-group>
            <b-input v-model="search" type="search" placeholder="Search..."/>
          </b-form-group>
        </div>
      </b-card-title>

      <b-row>
        <b-col cols="12">
          <b-card v-if="ui.filters.show" bg-variant="light-primary" body-class="px-1" class="mb-2">
            <div class="font-weight-bold font-small-3 d-flex align-self-center justify-content-between">
              <span v-b-toggle="'unassigned-students-filters-collapse'">Filters</span>
              <div class="d-flex align-items-center">
                <b-badge v-if="isFiltered" title="Active Filters" variant="primary" pill class="mr-1">{{ activeFilters.length }}</b-badge>
                <b-icon-x v-else style="width: 15px; height: 15px;"
                          class="cursor-pointer mr-1"
                          @click="ui.filters.show = false"/>
                <b-icon-chevron-down style="width: 14px; height: 14px;"
                                     class="cursor-pointer"
                                     :class="ui.filters.expanded ? 'rotate-180' : 'collapsed'"
                                     aria-controls="students-tab-filters-collapse"
                                     :aria-expanded="ui.filters.expanded ? 'true' : 'false'"
                                     @click="ui.filters.expanded = !ui.filters.expanded "/>
              </div>
            </div>
            <b-collapse id="unassigned-students-filters-collapse" v-model="ui.filters.expanded">
              <b-row class="mt-1">
                <b-col>
                  <b-form-group label="Ensemble" label-for="filter-ensemble">
                    <v-select v-model="filters.ensemble"
                              :loading="students.loading"
                              input-id="filter-ensemble"
                              placeholder="Ensemble"
                              :options="ensembles.items" label="name"
                              :reduce="option => option.id"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100 font-small-3"/>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Instrument" label-for="filter-instruments">
                    <v-select v-model="filters.instrument"
                              :loading="students.loading"
                              input-id="filter-instruments"
                              placeholder="Instrument"
                              :options="instruments.items" label="name"
                              :reduce="option => option.id"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100 font-small-3"/>
                  </b-form-group>
                </b-col>
            </b-row>
            </b-collapse>
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card v-if="ui.sorting.show" bg-variant="light-primary" body-class="px-1" class="mb-2">
            <div class="font-weight-bold font-small-3 d-flex align-self-center justify-content-between">
              <span v-b-toggle="'unassigned-students-sorting-collapse'">Sorting</span>
              <div class="d-flex align-items-center">
                <b-badge v-if="sorting.by.length" title="Active Sorting" variant="primary" pill class="mr-1">{{ sorting.by.length }}</b-badge>
                <b-icon-x v-else style="width: 15px; height: 15px;"
                          class="cursor-pointer mr-1"
                          @click="ui.sorting.show = false"/>
                <b-icon-chevron-down style="width: 14px; height: 14px;"
                                     class="cursor-pointer"
                                     :class="ui.sorting.expanded ? 'rotate-180' : 'collapsed'"
                                     aria-controls="students-tab-sorting-collapse"
                                     :aria-expanded="ui.sorting.expanded ? 'true' : 'false'"
                                     @click="ui.sorting.expanded = !ui.sorting.expanded "/>
              </div>
            </div>
            <b-collapse id="unassigned-students-sorting-collapse" v-model="ui.sorting.expanded">
              <b-form-group label="Sort By" label-for="sorting-by" class="mt-1">
                <sort-input v-model="sorting"/>
              </b-form-group>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>


      <b-overlay :show="students.loading">
        <b-list-group v-for="(group, index) in groupedUnassignedStudents" :key="index">
          <b-list-group-item tag="li" :class="['align-items-center p-50 rounded bg-light-primary', index === 0 ? 'mt-0' : 'mt-1']">
            <div class="d-flex justify-content-between align-items-center">
              <div v-b-toggle="`collapse-group-${group.id}`" class="py-1 pl-50 font-weight-bold d-flex flex-row w-100 align-items-center">
                <div>
                  {{ group.name }} <small>({{ group.students.length }})</small>
                </div>
              </div>
              <b-dropdown :id="`${group.id}-dropdown`"
                          variant="link"
                          no-caret dropright title="Options"
                          :right="$store.state.appConfig.isRTL"
                          :toggle-class="['px-50']">
                <template #button-content>
                  <b-icon-three-dots-vertical style="width: 14px; height: 14px"/>
                </template>
                <b-dropdown-item :id="`${group.id}-dropdown-item-assignMax`" :disabled="state.saving || atCapacity" @click="assignStudentsToCapacity(group.students)">
                  <feather-icon icon="MoveIcon"/>
                  <span class="align-middle ml-50">Fill Bus to Capacity</span>
                </b-dropdown-item>
                <b-dropdown-item :id="`${group.id}-dropdown-item-assignAll`" :disabled="state.saving" @click="assignStudents(group.students)">
                  <feather-icon icon="MoveIcon"/>
                  <span class="align-middle ml-50">Assign All Students</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <b-collapse :id="`collapse-group-${group.id}`">
              <vue-perfect-scrollbar :settings="scrollbarSettings" class="ps-unassigned-students">
                <b-list-group class="rounded p-50">
                  <b-list-group-item v-for="(student, index) in group.students"
                                     :id="`${group.id}-${student.id}-${student.selection.instrument.id}`"
                                     :key="`${group.id}-${student.id}-${student.selection.instrument.id}`"
                                     :disabled="state.saving"
                                     tag="li"
                                     :action="false"
                                     :class="['rounded border-0 shadow p-2', index === 0 ? 'mt-0' : 'mt-1']"
                                     @click="$event.stopPropagation()">
                    <b-row>
                      <b-col align-self="start" cols="auto" >
                        <b-avatar :variant="`light-primary`" size="44px" :badge-variant="getSexBadgeVariant(student)">
                          <font-awesome-icon :icon="getSexIcon(student)" style="width: 22px; height: 22px" />
                          <template #badge>
                            {{ getSexBadge(student) }}
                          </template>
                        </b-avatar>
                      </b-col>
                      <b-col align-self="center">
                        <div class="font-weight-bold">{{ student.name.first }} {{ student.name.last }}</div>
                        <div v-if="student.selection" class="font-small-3">
                          <span v-if="student.selection.ensemble">{{ student.selection.ensemble.name }}</span>
                          <span v-else class="text-danger">No Ensemble</span>
                          -
                          <span v-if="student.selection.instrument">{{ student.selection.instrument.name }}</span>
                          <span v-else class="text-danger">No Instrument</span>
                        </div>
                      </b-col>
                      <b-col align-self="center" cols="auto">
                        <b-button v-if="isDuplicate(student.id)" :id="`bus-student-${student.id}-duplicate`"
                                  size="sm" variant="link"
                                  :disabled="state.saving"
                                  class="btn-icon btn-icon rounded-circle">
                          <b-icon-exclamation-circle-fill variant="danger" style="width: 14px; height: 14px"/>
                          <b-popover :target="`bus-student-${student.id}-duplicate`" triggers="hover" placement="top">
                            <template #title>Duplicate Student</template>
                            <p>Duplicate student was detected. This student has most likely been accepted into multiple selections.</p>
                            <small>To resolve this, please decline one of the students selections.</small>
                          </b-popover>
                        </b-button>
                        <b-button v-else :id="`bus-student-${student.id}-assign`"
                                  size="sm" variant="link"
                                  :disabled="state.saving || atCapacity"
                                  class="btn-icon btn-icon rounded-circle"
                                  @click="assignStudentClick(student)">
                          <b-icon-plus-circle style="width: 14px; height: 14px"/>
                          <b-tooltip :target="`bus-student-${student.id}-assign`" triggers="hover" variant="primary">Assign</b-tooltip>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </vue-perfect-scrollbar>
            </b-collapse>
          </b-list-group-item>
        </b-list-group>
      </b-overlay>
    </b-card>
    <debug v-if="state.debug">
      {{ unassignedStudents }}
    </debug>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select';
import SortInput from '@/components/SortInput.vue';
import events from '@/mixins/event.mixin';
import notify from '@/mixins/notify.mixin';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import _ from 'lodash';
import Fuse from 'fuse.js';

export default {
  name: 'UnassignedStudents',
  components: {
    SortInput,
    vSelect,
    VuePerfectScrollbar
  },
  mixins: [ events, notify ],
  props: {
    students: {
      type: Object,
      required: true
    },
    instruments: {
      type: Object,
      required: true
    },
    ensembles: {
      type: Object,
      required: true
    },
    selected: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      required: true
    },
    atCapacity: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      search: null,
      filters: {
        instrument: null,
        ensemble: null
      },
      sorting: {
        by: ['instrument.name', 'name.last', 'name.first'],
        desc: false,
        drag: false,
        options: [
          { label: 'First Name', value: 'name.first'},
          { label: 'Last Name', value: 'name.last'},
          { label: 'Instrument', value: 'instrument.name'},
        ]
      },
      ui: {
        filters: {
          show: false,
          expanded: false
        },
        sorting: {
          show: false,
          expanded: false
        },
      },
    }
  },
  computed: {
    groupedUnassignedStudents() {
      // First, create a map of all ensembles with empty student arrays
      const ensembleMap = this.students.items.reduce((acc, student) => {
        acc[student?.selection?.ensemble?.id] = {
          id: student?.selection?.ensemble?.id,
          name: student?.selection?.ensemble?.name,
          students: []
        };
        return acc;
      }, {});

      // Then, populate the student arrays for each ensemble
      this.unassignedStudents.forEach((student) => {
        const ensembleId = student?.selection?.ensemble?.id;
        ensembleMap[ensembleId].students.push(student);
      });

      // Finally, sort and return the ensembles as an array
      return Object.values(ensembleMap).sort((a, b) => a?.name?.localeCompare(b?.name)).filter(ensemble => ensemble.id);
    },
    unassignedStudents() {
      const items = this.students.items.filter(student => !this.selected.bus.studentEvents.items.find(event => event.student?.id === student.id) && !student.event?.busId)
      const keys = [
        { name: 'name.first' },
        { name: 'name.last'},
        { name: 'selection.ensemble.id' },
        { name: 'selection.ensemble.name' },
        { name: 'selection.instrument.name'},
        { name: 'selection.instrument.id'},
      ]
      const fuse = new Fuse(items, {
        keys: keys,
        threshold: 0.2,
        shouldSort: true,
      })

      const query = { $and: [ ] }
      if(this.filters.ensemble) {
        query.$and.push({ 'selection.ensemble.id': this.filters.ensemble })
      }
      if(this.filters.instrument) {
        query.$and.push({ 'selection.instrument.id': this.filters.instrument })
      }

      /** Build the Optional Or Clauses  **/
      if(this.search) {
        const or = []
        keys.forEach(key => {
          or.push({[key.name]: `${this.search}` })
        })
        query.$and.push({ $or: or }) //Push our $or clauses into the $and clause
      }

      let searchedItems;
      if(query.$and.length) { searchedItems = fuse.search(query).map(({ item }) => item) }
      else { searchedItems = items }
      if (this.sorting?.by?.length) {
        return this.sortItems(this.sorting, searchedItems);
      }
      return searchedItems;
    },
    duplicateStudents() {
      // Count the occurrence of each student ID.
      const counts = this.students.items.reduce((acc, student) => {
        acc[student.id] = (acc[student.id] || 0) + 1;
        return acc;
      }, {});

      // Filter out the student IDs that only occur once to find duplicates.
      const duplicates = this.students.items
          .filter(student => counts[student.id] > 1)
          .reduce((acc, student) => {
            // If the student is not already in the accumulator, add them.
            if (!acc[student.id]) {
              acc[student.id] = {
                ...student,
                count: counts[student.id], // The count of duplicates
              };
            }
            return acc;
          }, {});

      // Convert the duplicates map to an array.
      return Object.values(duplicates);
    },
    isFiltered() {
      return this.search || (this.filters && Object.values(this.filters).some(f => f !== null));
    },
    activeFilters() {
      return Object.values(this.filters).filter(f => f !== null);
    },
  },
  methods: {
    assignStudentsToCapacity(students) {
      this.$emit('assign:capacity', students)
    },
    assignStudents(students) {
      this.$emit('assign:students', students)
    },
    assignStudentClick(student) {
      this.$emit('assign:student', student)
    },
    isDuplicate(studentId) {
      return this.duplicateStudents?.some(s => s.id === studentId) ?? false;
    },
  }
}
</script>

<style scoped lang="scss">
.ps-unassigned-students {
  max-height: 60vh;

  .list-group-item:hover {
    background-color: var(--white);
    cursor: default;
  }

  .list-group:empty,
  .list-group > div:empty {
    background-color: var(--white);
  }

  .list-group:empty:before,
  .list-group > div:empty:before {
    content: 'No Students Associated With This Ensemble...';
  }
}
</style>

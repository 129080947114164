<template>
	<page-layout ref="layout" :show-print-option="false" @refresh="refresh">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`Events - ${$store.state.settings.app.current.title}`" />
      <template v-if="id">
        <b-breadcrumb-item text="Badges" :to="{ name: 'events-badges', params: { id: null } }" />
        <b-breadcrumb-item v-if="selected.badge" :text="selected.badge.name" active />
      </template>
      <template v-else>
        <b-breadcrumb-item text="Badges" active />
      </template>
    </template>

    <template v-if="hasChanges" #actions="{ }">
      <b-button v-b-tooltip="'Revert Changes'" variant="link" size="sm" class="btn-icon mr-50" @click="resetSelectedBadgeSettings">
        <b-icon-arrow-counterclockwise />
      </b-button>
      <can do="update" on="events-badges">
        <b-button v-b-tooltip="'Update'" variant="link" size="sm" class="btn-icon" @click="$refs.settings.updateSettings(selected.badge.key, selected.badge.settings)">
          <b-icon-save2 />
        </b-button>
      </can>
		</template>

		<template #dropdown-options="{ state }">
      <can do="update" on="events-badges">
        <b-dropdown-item @click="state.editing = !state.editing">
          <font-awesome-icon icon="fa-solid fa-pen-to-square" />
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
      </can>
			<b-dropdown-item @click="refresh">
				<feather-icon icon="RotateCwIcon"/>
				<span class="align-middle ml-50">Refresh</span>
			</b-dropdown-item>
      <b-dropdown-divider/>
      <b-dropdown-item @click="sidebar = !sidebar">
        <feather-icon icon="SidebarIcon"/>
        <span class="align-middle ml-50">{{ sidebar ? 'Hide' : 'Show' }} Sidebar</span>
      </b-dropdown-item>
		</template>

		<template #content="{ state }">
      <event-layout :show-sidebar="sidebar" :show-sidebar-right="state.editing" :auto-toggle-sidebar="false">
        <template #sidebar>
          <events-nav title="Badges"
                      :selected-id="id"
                      :items="badges.items"
                      :filters="badges.filters"
                      :sorting="badges.sorting"
                      :show-search="false"
                      @search="(text) => badges.search = text"
                      @sort="(sorting) => badges.sorting = sorting"
                      @select="(item) => selectBadge(item)"
                      @deselect="deselectBadge">

            <template #item="{ item, emptyTextClass }">
              <span v-if="item.name">{{ item.name }}</span>
              <span v-else :class="emptyTextClass">Unnamed Badge</span>
            </template>

            <template #filters></template>
          </events-nav>
          <!-- Print Card -->
          <b-card class="bus-roster-card mb-2" body-class="pt-" img-alt="Bus" img-top tag="article">
            <b-card-title class="mb-0 d-flex align-items-center justify-content-between">
              Print Options
              <div class="d-flex align-items-center">
               <b-button variant="link" size="sm" class="px-0" @click="options.showBlanks = !options.showBlanks">
                 <span class="align-middle mr-50">Blanks</span>
                 <b-icon :icon="options.showBlanks ? 'check-square' : 'square'" style="width: 14px; height: 14px;"/>
                </b-button>
              </div>
            </b-card-title>

            <b-img :src="image" />
            <b-card-text class="mt-50">
              <span class="font-small-3">For individual name badges, navigate to the <u><b-link :to="{ name: 'events-students' }">students</b-link></u> event page and select a student. Then select the Badges tab.</span>
            </b-card-text>

            <template v-if="!options.showBlanks">
              <!-- Filters -->
              <div class="bg-light-primary rounded px-1 py-75 mb-1">
                <div v-b-toggle.badge-filters class="d-flex align-items-center justify-content-between">
                  Filters
                  <b-button variant="link" size="sm" class="px-50">
                    <b-icon-chevron-up :class="{ 'rotate-180': filters.collapsed}" style="width: 12px; height: 12px;" />
                  </b-button>
                </div>
                <b-collapse id="badge-filters" v-model="filters.collapsed">
                  <div class="pt-1">
                    <b-form-group label="School" label-for="schools">
                      <v-select id="schools"
                                v-model="filters.schools.value" label="label"
                                :options="filters.schools.items"
                                :loading="filters.schools.loading"
                                :reduce="option => option.value"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                    </b-form-group>

                    <b-form-group label="Ensemble" label-for="ensembles">
                      <v-select id="ensembles"
                                v-model="filters.ensembles.values" multiple
                                :options="filters.ensembles.items"
                                :loading="filters.ensembles.loading"
                                :reduce="option => option.value"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                    </b-form-group>

                    <b-form-group label="Instrument" label-for="instruments">
                      <v-select id="instruments"
                                v-model="filters.instruments.value"
                                :options="filters.instruments.items"
                                :loading="filters.instruments.loading"
                                :reduce="option => option.value"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                    </b-form-group>

                    <b-form-group label="Student" label-for="students">
                      <v-select id="students"
                                v-model="filters.students.value"
                                :options="filters.students.items"
                                :loading="filters.students.loading"
                                :reduce="option => option.value"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                    </b-form-group>

                    <b-form-group label="Teacher" label-for="teachers">
                      <v-select id="teachers"
                                v-model="filters.teachers.value"
                                :options="filters.teachers.items"
                                :loading="filters.teachers.loading"
                                :reduce="option => option.value"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                    </b-form-group>

                    <b-form-group label="Bus" label-for="buses">
                      <v-select id="buses"
                                v-model="filters.buses.value"
                                :options="filters.buses.items"
                                :loading="filters.buses.loading"
                                :reduce="option => option.value"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                        <template #option="{ label, value }">
                          <span :class="[value === 'undefined' ? 'text-danger' : '']">{{ label }}</span>
                        </template>
                        <template #selected-option="{ label, value }">
                          <span :class="[value === 'undefined' ? 'text-danger' : '']">{{ label }}</span>
                        </template>
                      </v-select>
                    </b-form-group>
                  </div>

                </b-collapse>
              </div>
              <!-- Sorting -->
              <div class="bg-light-primary rounded px-1 py-75 mb-1">
                <div v-b-toggle.badge-sorting class="d-flex align-items-center justify-content-between">
                  Sorting
                  <b-button variant="link" size="sm" class="px-50">
                    <b-icon-chevron-up :class="{ 'rotate-180': sorting.collapsed}" style="width: 12px; height: 12px;"/>
                  </b-button>
                </div>
                <b-collapse id="badge-sorting" v-model="sorting.collapsed">
                  <div class="pt-1">
                    <b-form-group label-for="sorting-by">
                      <sort-input v-model="sorting" />
                    </b-form-group>
                  </div>
                </b-collapse>
              </div>
              <!-- Paging -->
              <div class="py-50">
                <b-form-group label="Pages" label-for="paging-size">
                  <v-select id="paging-size"
                            v-model="paging.size"
                            :options="paging.sizes"
                            :reduce="option => option.value"
                            :clearable="false"
                            append-to-body/>
                </b-form-group>
                <b-pagination v-if="paging.size !== -1"
                              id="paging-page"
                              v-model="paging.page"
                              :total-rows="paging.total"
                              :per-page="paging.size"
                              first-number
                              last-number
                              :limit="3"
                              pills align="fill">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="14"/>
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="14"/>
                  </template>
                </b-pagination>
              </div>
            </template>
            <b-button block variant="primary" @click="printWindow()">Print Name Badges</b-button>
          </b-card>
        </template>
        <template #content>
          <b-overlay :show="selected.loading" :opacity="1" class="bg-transparent" style="min-height: 100px;">
            <template v-if="selected.badge.settings">
              <template v-if="options.showBlanks">
                <div v-for="currentPage in 1" :key="currentPage"
                     class="name-badge-container" :class="hover.container ? 'hover-container' : ''"
                     :style="selected.badge.settings.container ? computedStyle(selected.badge.settings.container.style) : null">
                  <badge v-for="(item, index) in getPageItems(filteredSelections, currentPage, 6)" :key="index"
                         :settings="selected.badge.settings"
                         :include-student-short-name="selected.badge.settings.student.short.enabled"
                         :include-student-full-name="selected.badge.settings.student.full.enabled"
                         :include-ensemble="selected.badge.settings.ensemble.enabled"
                         :include-bus="selected.badge.settings.bus.enabled"

                         :is-blanks="true"

                         :hover-badge="hover.badge"
                         :hover-logo="hover.logo"
                         :hover-footer="hover.footer"
                  />
                </div>
              </template>
              <template v-else-if="state.editing">
                <div class="d-flex flex-row gap-2 flex-wrap">
                  <div v-for="currentPage in 1" :key="currentPage"
                       class="name-badge-container" :class="hover.container ? 'hover-container' : ''"
                       :style="selected.badge.settings.container ? computedStyle(selected.badge.settings.container.style) : null">
                    <badge v-for="(item, index) in getPageItems(filteredSelections, currentPage, 6)" :key="index"
                           :settings="selected.badge.settings"
                           :include-student-short-name="selected.badge.settings.student.short.enabled"
                           :include-student-full-name="selected.badge.settings.student.full.enabled"
                           :include-ensemble="selected.badge.settings.ensemble.enabled"
                           :include-bus="selected.badge.settings.bus.enabled"

                           :hover-badge="hover.badge"
                           :hover-logo="hover.logo"
                           :hover-student-short-name="hover.studentShort"
                           :hover-student-full-name="hover.studentFull"
                           :hover-ensemble="hover.ensemble"
                           :hover-bus="hover.bus"
                           :hover-footer="hover.footer"

                           :student-name-short="hasValue(item, 'application.student.name') ? item.application.student.name : null"
                           :student-name-full="hasValue(item, 'application.student.name') ? item.application.student.name : null"
                           :ensemble="hasValue(item, 'ensemble.name') ? item.ensemble.name : null"
                           :bus="hasValue(item, 'application.student.event.bus') ? item.application.student.event.bus : null"
                           class="name-badge-editor"
                    />
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="d-flex flex-row gap-2 flex-wrap">
                  <div v-for="currentPage in getPageCount(filteredSelections, 6)" :key="currentPage"
                       class="name-badge-container"
                       :style="selected.badge.settings.container ? computedStyle(selected.badge.settings.container.style) : null">
                    <badge v-for="(selection, index) in getPageItems(filteredSelections, currentPage, 6)" :key="index"
                           :settings="selected.badge.settings"
                           :include-student-short-name="selected.badge.settings.student.short.enabled"
                           :include-student-full-name="selected.badge.settings.student.full.enabled"
                           :include-ensemble="selected.badge.settings.ensemble.enabled"
                           :include-bus="selected.badge.settings.bus.enabled"

                           :student-name-short="hasValue(selection, 'application.student.name') ? selection.application.student.name : null"
                           :student-name-full="hasValue(selection, 'application.student.name') ? selection.application.student.name : null"
                           :ensemble="hasValue(selection, 'ensemble.name') ? selection.ensemble.name : null"
                           :bus="hasValue(selection, 'application.student.event.bus') ? selection.application.student.event.bus : null"
                    />
                  </div>
                  <div class="name-badge-container-paging d-flex align-items-center justify-content-center d-print-none w-100">
                    <span v-if="selections.items.length > 0" class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.of }} Badges</span>
                    <span v-else class="text-muted">Showing 0 of 0 Badges</span>
                  </div>
                </div>

              </template>
            </template>
          </b-overlay>
        </template>
        <template #sidebar-right>
          <!-- Settings -->
          <template v-if="selected.badge && selected.badge.loaded">
            <can do="update" on="events-badges">
              <settings-container ref="settings"
                                  title="Badge Settings"
                                  :settings="selected.badge.settings"
                                  :settings-key="selected.badge.key"
                                  :has-changed="hasChanges"
                                  :modal-text="`Are you sure you want to clear the <ins>${selected.badge.name}</ins> badge settings?`"
                                  @updated="updateSelectedBadgeInitial"
                                  @revert="resetSelectedBadgeSettings">
                <!-- Container -->
                <b-card no-body class="mb-0">
                  <options-header v-b-toggle.options-container v-b-hover="hoverContainer" header-class="options-header" title="Container"></options-header>
                  <b-collapse id="options-container" role="tabpanel" class="options-collapse">
                    <b-card-body body-bg-variant="light-primary">

                      <!-- Padding Options -->
                      <options-header v-b-toggle.options-container-padding header-class="options-header" title="Padding"></options-header>
                      <b-collapse id="options-container-padding" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <options-sync-container>
                            <option-value-unit v-model="selected.badge.settings.container.style.paddingTop" :disabled="!state.editing" label="Top" input-id="container-padding-top" placeholder="0.1667in"/>
                            <option-value-unit v-model="selected.badge.settings.container.style.paddingBottom" :disabled="!state.editing" label="Bottom" input-id="container-padding-bottom" placeholder="0.1667in"/>
                            <option-value-unit v-model="selected.badge.settings.container.style.paddingLeft" :disabled="!state.editing" label="Left" input-id="container-padding-left" placeholder="0.1667in"/>
                            <option-value-unit v-model="selected.badge.settings.container.style.paddingRight" :disabled="!state.editing" label="Right" input-id="container-padding-right" placeholder="0.1667in"/>
                          </options-sync-container>
                        </b-card-body>
                      </b-collapse>

                      <!-- Grid Options -->
                      <options-header v-b-toggle.options-container-grid header-class="options-header" title="Grid"></options-header>
                      <b-collapse id="options-container-grid" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <options-sync-container>
                            <option-value-unit v-model="selected.badge.settings.container.style.gridColumnGap" :disabled="!state.editing" label="Column Gap" input-id="container-column-gap" placeholder="0.1667in"/>
                            <option-value-unit v-model="selected.badge.settings.container.style.gridRowGap" :disabled="!state.editing" label="Row Gap" input-id="container-column-gap" placeholder="0.1667in"/>
                          </options-sync-container>
                        </b-card-body>
                      </b-collapse>

                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!-- Badge -->
                <b-card no-body class="mb-0">
                  <options-header v-b-toggle.options-general v-b-hover="hoverBadge" header-class="options-header" title="Badge"></options-header>
                  <b-collapse id="options-general" role="tabpanel" class="options-collapse">
                    <b-card-body body-bg-variant="light-primary">
                      <!-- Font Options -->
                      <options-header v-b-toggle.options-general-font header-class="options-header" title="Font"></options-header>
                      <b-collapse id="options-general-font" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <option-color v-model="selected.badge.settings.badge.style.color" :disabled="!state.editing" label="Font Color" input-id="general-color"/>
                          <option-select v-model="selected.badge.settings.badge.style.fontFamily" :disabled="!state.editing" label="Font Family" input-id="general-font-family" :options="options.fontFamily" placeholder="Montserrat, Helvetica, Arial, serif"/>
                          <option-select v-model="selected.badge.settings.badge.style.fontWeight" :disabled="!state.editing" label="Font Weight" input-id="general-font-weight" :options="options.fontWeight" placeholder="unset"/>
                        </b-card-body>
                      </b-collapse>

                      <!-- Border Options -->
                      <options-header v-b-toggle.options-general-container-border header-class="options-header" title="Border"></options-header>
                      <b-collapse id="options-general-container-border" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <option-color v-model="selected.badge.settings.badge.style.borderColor" :disabled="!state.editing" label="Border Color" input-id="general-border-color"/>
                          <option-value-unit v-model="selected.badge.settings.badge.style.borderWidth" :disabled="!state.editing" label="Border Width" input-id="general-border-width" placeholder="unset"/>
                          <option-select v-model="selected.badge.settings.badge.style.borderStyle"
                                         :options="options.borderStyle"
                                         :disabled="!state.editing"
                                         label="Border Style" input-id="general-border-style" placeholder="unset"/>
                        </b-card-body>
                      </b-collapse>

                      <!-- Padding Options -->
                      <options-header v-b-toggle.options-general-padding header-class="options-header" title="Padding"></options-header>
                      <b-collapse id="options-general-padding" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <options-sync-container>
                            <option-value-unit v-model="selected.badge.settings.badge.style.paddingTop" :disabled="!state.editing" label="Top" input-id="general-padding-top" placeholder="unset"/>
                            <option-value-unit v-model="selected.badge.settings.badge.style.paddingBottom" :disabled="!state.editing" label="Bottom" input-id="general-padding-bottom" placeholder="unset"/>
                            <option-value-unit v-model="selected.badge.settings.badge.style.paddingLeft" :disabled="!state.editing" label="Left" input-id="general-padding-left" placeholder="unset"/>
                            <option-value-unit v-model="selected.badge.settings.badge.style.paddingRight" :disabled="!state.editing" label="Right" input-id="general-padding-right" placeholder="unset"/>
                          </options-sync-container>
                        </b-card-body>
                      </b-collapse>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!-- Logo -->
                <b-card no-body class="mb-0">
                  <options-header v-b-toggle.options-logo v-b-hover="hoverLogo" header-class="options-header" title="Logo"></options-header>
                  <b-collapse id="options-logo" role="tabpanel" class="options-collapse">
                    <b-card-body body-bg-variant="light-primary">

                      <!-- Image Options -->
                      <options-header v-b-toggle.options-logo-image header-class="options-header" title="Image"></options-header>
                      <b-collapse id="options-logo-image" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <option-image v-model="selected.badge.settings.logo.src" label="Url" input-id="logo-src"/>
                          <option-select v-model="selected.badge.settings.logo.style.objectFit"
                                         :options="options.fit"
                                         :disabled="!state.editing"
                                         label="Fit" input-id="logo-fit" placeholder="cover"/>
                        </b-card-body>
                      </b-collapse>

                      <!-- Border Options -->
                      <options-header v-b-toggle.options-logo-border header-class="options-header" title="Border"></options-header>
                      <b-collapse id="options-logo-border" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <option-color v-model="selected.badge.settings.logo.style.borderColor" :disabled="!state.editing" label="Border Color" input-id="logo-border-color"/>
                          <option-value-unit v-model="selected.badge.settings.logo.style.borderWidth" :disabled="!state.editing" label="Border Width" input-id="logo-border-width" placeholder="1px"/>
                          <option-select v-model="selected.badge.settings.logo.style.borderStyle"
                                         :options="options.borderStyle"
                                         :disabled="!state.editing"
                                         label="Border Style" input-id="logo-border-style" placeholder="dashed"/>
                          <option-select v-model="selected.badge.settings.logo.style.borderRadius"
                                         :options="options.radius"
                                         :disabled="!state.editing"
                                         label="Border Radius" input-id="logo-radius" placeholder="round"/>
                        </b-card-body>
                      </b-collapse>

                      <!-- Dimensions Options -->
                      <options-header v-b-toggle.options-logo-dimensions header-class="options-header" title="Dimensions"></options-header>
                      <b-collapse id="options-logo-dimensions" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <options-sync-container :disabled="!state.editing">
                            <option-value-unit v-model="selected.badge.settings.logo.style.width" :disabled="!state.editing" label="Width" input-id="logo-width" placeholder="1in"/>
                            <option-value-unit v-model="selected.badge.settings.logo.style.height" :disabled="!state.editing" label="Height" input-id="logo-height" placeholder="1in"/>
                          </options-sync-container>
                        </b-card-body>
                      </b-collapse>

                      <!-- Margin Options -->
                      <options-header v-b-toggle.options-logo-margin header-class="options-header" title="Margin"></options-header>
                      <b-collapse id="options-logo-margin" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <options-sync-container :disabled="!state.editing">
                            <option-value-unit v-model="selected.badge.settings.logo.style.marginTop" :disabled="!state.editing" label="Top" input-id="logo-margin-top" placeholder="1rem"/>
                            <option-value-unit v-model="selected.badge.settings.logo.style.marginBottom" :disabled="!state.editing" label="Bottom" input-id="logo-margin-bottom" placeholder="1rem"/>
                          </options-sync-container>
                        </b-card-body>
                      </b-collapse>


                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!-- Student -->
                <b-card no-body class="mb-0">
                  <options-header v-b-toggle.options-student header-class="options-header" title="Student"/>
                  <b-collapse id="options-student" role="tabpanel" class="options-collapse">
                    <b-card-body body-bg-variant="light-primary">

                      <!-- Short Name -->
                      <options-header v-b-toggle.options-student-short v-b-hover="hoverStudentShort" header-class="options-header" title="Short Name"/>
                      <b-collapse id="options-student-short" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">

                          <!-- Enabled -->
                          <option-enabled :enabled.sync="selected.badge.settings.student.short.enabled" :disabled="!state.editing" />

                          <!-- Text Options -->
                          <options-header v-b-toggle.options-student-short-text header-class="options-header" title="Text"></options-header>
                          <b-collapse id="options-student-short-text" role="tabpanel" class="options-collapse">
                            <b-card-body body-bg-variant="white">
                              <option-select v-model="selected.badge.settings.student.short.textFormat"
                                             :options="options.student.short.textFormat"
                                             :disabled="!state.editing"
                                             label="Text Format" input-id="student-short-text-format" placeholder="Preferred Name or First Name"/>
                            </b-card-body>
                          </b-collapse>

                          <!-- Font Options -->
                          <options-header v-b-toggle.options-student-short-font header-class="options-header" title="Font"></options-header>
                          <b-collapse id="options-student-short-font" role="tabpanel" class="options-collapse">
                            <b-card-body body-bg-variant="white">
                              <option-value-unit v-model="selected.badge.settings.student.short.style.fontSize" :disabled="!state.editing" label="Font Size" input-id="student-short-font-size" placeholder="1.5rem"/>
                              <option-select v-model="selected.badge.settings.student.short.style.fontWeight" :disabled="!state.editing" label="Font Weight" input-id="student-short-font-weight" :options="options.fontWeight" placeholder="bold"/>
                            </b-card-body>
                          </b-collapse>

                          <!-- Padding Options -->
                          <options-header v-b-toggle.options-student-short-padding header-class="options-header" title="Padding"></options-header>
                          <b-collapse id="options-student-short-padding" role="tabpanel" class="options-collapse">
                            <b-card-body body-bg-variant="white">
                              <options-sync-container :disabled="!state.editing">
                                <option-value-unit v-model="selected.badge.settings.student.short.style.paddingTop" :disabled="!state.editing" label="Top" input-id="student-short-padding-top" placeholder="unset"/>
                                <option-value-unit v-model="selected.badge.settings.student.short.style.paddingBottom" :disabled="!state.editing" label="Bottom" input-id="student-short-padding-bottom" placeholder="unset"/>
                                <option-value-unit v-model="selected.badge.settings.student.short.style.paddingLeft" :disabled="!state.editing" label="Left" input-id="student-short-padding-left" placeholder="unset"/>
                                <option-value-unit v-model="selected.badge.settings.student.short.style.paddingRight" :disabled="!state.editing" label="Right" input-id="student-short-padding-right" placeholder="unset"/>
                              </options-sync-container>
                            </b-card-body>
                          </b-collapse>

                          <!-- Margin Options -->
                          <options-header v-b-toggle.options-student-short-margin header-class="options-header" title="Margin"></options-header>
                          <b-collapse id="options-student-short-margin" role="tabpanel" class="options-collapse">
                            <b-card-body body-bg-variant="white">
                              <options-sync-container :disabled="!state.editing">
                                <option-value-unit v-model="selected.badge.settings.student.short.style.marginTop" :disabled="!state.editing" label="Top" input-id="student-short-margin-top" placeholder="0rem"/>
                                <option-value-unit v-model="selected.badge.settings.student.short.style.marginBottom" :disabled="!state.editing" label="Bottom" input-id="student-short-margin-bottom" placeholder="0.5rem"/>
                                <option-value-unit v-model="selected.badge.settings.student.short.style.marginLeft" :disabled="!state.editing" label="Left" input-id="student-short-margin-left" placeholder="0.5rem"/>
                                <option-value-unit v-model="selected.badge.settings.student.short.style.marginRight" :disabled="!state.editing" label="Right" input-id="student-short-margin-right" placeholder="0.5rem"/>
                              </options-sync-container>
                            </b-card-body>
                          </b-collapse>

                        </b-card-body>
                      </b-collapse>

                      <!-- Full Name -->
                      <options-header v-b-toggle.options-student-full v-b-hover="hoverStudentFull" header-class="options-header" title="Full Name"/>
                      <b-collapse id="options-student-full" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">

                          <!-- Enabled -->
                          <option-enabled :enabled.sync="selected.badge.settings.student.full.enabled" :disabled="!state.editing" />

                          <!-- Text Options -->
                          <options-header v-b-toggle.options-student-full-text header-class="options-header" title="Text"></options-header>
                          <b-collapse id="options-student-full-text" role="tabpanel" class="options-collapse">
                            <b-card-body body-bg-variant="white">
                              <option-select v-model="selected.badge.settings.student.full.textFormat"
                                             :options="options.student.full.textFormat"
                                             :disabled="!state.editing"
                                             label="Text Format" input-id="student-full-text-format" placeholder="First, Last"/>
                            </b-card-body>
                          </b-collapse>

                          <!-- Font Options -->
                          <options-header v-b-toggle.options-student-full-font header-class="options-header" title="Font"></options-header>
                          <b-collapse id="options-student-full-font" role="tabpanel" class="options-collapse">
                            <b-card-body body-bg-variant="white">
                              <option-value-unit v-model="selected.badge.settings.student.full.style.fontSize" :disabled="!state.editing" label="Font Size" input-id="student-full-font-size" placeholder="1rem"/>
                              <option-select v-model="selected.badge.settings.student.full.style.fontWeight" :disabled="!state.editing" label="Font Weight" input-id="student-full-font-weight" :options="options.fontWeight" placeholder="bold"/>
                            </b-card-body>
                          </b-collapse>

                          <!-- Padding Options -->
                          <options-header v-b-toggle.options-student-full-padding header-class="options-header" title="Padding"></options-header>
                          <b-collapse id="options-student-full-padding" role="tabpanel" class="options-collapse">
                            <b-card-body body-bg-variant="white">
                              <options-sync-container :disabled="!state.editing">
                                <option-value-unit v-model="selected.badge.settings.student.full.style.paddingTop" :disabled="!state.editing" label="Top" input-id="student-full-padding-top" placeholder="unset"/>
                                <option-value-unit v-model="selected.badge.settings.student.full.style.paddingBottom" :disabled="!state.editing" label="Bottom" input-id="student-full-padding-bottom" placeholder="unset"/>
                                <option-value-unit v-model="selected.badge.settings.student.full.style.paddingLeft" :disabled="!state.editing" label="Left" input-id="student-full-padding-left" placeholder="unset"/>
                                <option-value-unit v-model="selected.badge.settings.student.full.style.paddingRight" :disabled="!state.editing" label="Right" input-id="student-full-padding-right" placeholder="unset"/>
                              </options-sync-container>
                            </b-card-body>
                          </b-collapse>

                          <!-- Margin Options -->
                          <options-header v-b-toggle.options-student-full-margin header-class="options-header" title="Margin"></options-header>
                          <b-collapse id="options-student-full-margin" role="tabpanel" class="options-collapse">
                            <b-card-body body-bg-variant="white">
                              <options-sync-container :disabled="!state.editing">
                                <option-value-unit v-model="selected.badge.settings.student.full.style.marginTop" :disabled="!state.editing" label="Top" input-id="student-full-margin-top" placeholder="0rem"/>
                                <option-value-unit v-model="selected.badge.settings.student.full.style.marginBottom" :disabled="!state.editing" label="Bottom" input-id="student-full-margin-bottom" placeholder="0.5rem"/>
                                <option-value-unit v-model="selected.badge.settings.student.full.style.marginLeft" :disabled="!state.editing" label="Left" input-id="student-full-margin-left" placeholder="0.5rem"/>
                                <option-value-unit v-model="selected.badge.settings.student.full.style.marginRight" :disabled="!state.editing" label="Right" input-id="student-full-margin-right" placeholder="0.5rem"/>
                              </options-sync-container>
                            </b-card-body>
                          </b-collapse>

                        </b-card-body>
                      </b-collapse>

                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!-- Ensemble -->
                <b-card no-body class="mb-0">
                  <options-header v-b-toggle.options-ensemble v-b-hover="hoverEnsemble" header-class="options-header" title="Ensemble"/>
                  <b-collapse id="options-ensemble" role="tabpanel" class="options-collapse">
                    <b-card-body body-bg-variant="light-primary">
                      <!-- Enabled -->
                      <option-enabled :enabled.sync="selected.badge.settings.ensemble.enabled" :disabled="!state.editing" />

                      <!-- Font Options -->
                      <options-header v-b-toggle.options-ensemble-font header-class="options-header" title="Font"></options-header>
                      <b-collapse id="options-ensemble-font" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <option-value-unit v-model="selected.badge.settings.ensemble.style.fontSize" :disabled="!state.editing" label="Font Size" input-id="ensemble-font-size" placeholder="1rem"/>
                          <option-select v-model="selected.badge.settings.ensemble.style.fontWeight" :disabled="!state.editing" label="Font Weight" input-id="ensemble-font-weight" :options="options.fontWeight" placeholder="normal"/>
                        </b-card-body>
                      </b-collapse>

                      <!-- Padding Options -->
                      <options-header v-b-toggle.options-ensemble-padding header-class="options-header" title="Padding"></options-header>
                      <b-collapse id="options-ensemble-padding" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <options-sync-container :disabled="!state.editing">
                            <option-value-unit v-model="selected.badge.settings.ensemble.style.paddingTop" :disabled="!state.editing" label="Top" input-id="ensemble-padding-top" placeholder="unset"/>
                            <option-value-unit v-model="selected.badge.settings.ensemble.style.paddingBottom" :disabled="!state.editing" label="Bottom" input-id="ensemble-padding-bottom" placeholder="unset"/>
                            <option-value-unit v-model="selected.badge.settings.ensemble.style.paddingLeft" :disabled="!state.editing" label="Left" input-id="ensemble-padding-left" placeholder="unset"/>
                            <option-value-unit v-model="selected.badge.settings.ensemble.style.paddingRight" :disabled="!state.editing" label="Right" input-id="ensemble-padding-right" placeholder="unset"/>
                          </options-sync-container>
                        </b-card-body>
                      </b-collapse>

                      <!-- Margin Options -->
                      <options-header v-b-toggle.options-ensemble-margin header-class="options-header" title="Margin"></options-header>
                      <b-collapse id="options-ensemble-margin" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <options-sync-container :disabled="!state.editing">
                            <option-value-unit v-model="selected.badge.settings.ensemble.style.marginTop" :disabled="!state.editing" label="Top" input-id="ensemble-margin-top" placeholder="0rem"/>
                            <option-value-unit v-model="selected.badge.settings.ensemble.style.marginBottom" :disabled="!state.editing" label="Bottom" input-id="ensemble-margin-bottom" placeholder="0.5rem"/>
                            <option-value-unit v-model="selected.badge.settings.ensemble.style.marginLeft" :disabled="!state.editing" label="Left" input-id="ensemble-margin-left" placeholder="0.5rem"/>
                            <option-value-unit v-model="selected.badge.settings.ensemble.style.marginRight" :disabled="!state.editing" label="Right" input-id="ensemble-margin-right" placeholder="0.5rem"/>
                          </options-sync-container>
                        </b-card-body>
                      </b-collapse>

                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!-- Bus -->
                <template v-if="selected.badge.id === 'conference'">
                  <b-card no-body class="mb-0">
                    <options-header v-b-toggle.options-bus v-b-hover="hoverBus" header-class="options-header" title="Bus"/>
                    <b-collapse id="options-bus" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="light-primary">

                        <!-- Enabled -->
                        <option-enabled :enabled.sync="selected.badge.settings.bus.enabled" :disabled="!state.editing" />

                        <!-- Text Options -->
                        <options-header v-b-toggle.options-bus-text header-class="options-header" title="Text"/>
                        <b-collapse id="options-bus-text" role="tabpanel" class="options-collapse">
                          <b-card-body body-bg-variant="white">
                            <option-value v-model="selected.badge.settings.bus.text" :disabled="!state.editing" input-id="bus-text-text" label="Prefix" />
                            <option-select v-model="selected.badge.settings.bus.textFormat"
                                           :options="options.bus.textFormat"
                                           :disabled="!state.editing"
                                           label="Text Format" input-id="bus-text-text-format" placeholder="Bus Name"/>
                          </b-card-body>
                        </b-collapse>

                        <!-- Font Options -->
                        <options-header v-b-toggle.options-bus-font header-class="options-header" title="Font"/>
                        <b-collapse id="options-bus-font" role="tabpanel" class="options-collapse">
                          <b-card-body body-bg-variant="white">
                            <option-value-unit v-model="selected.badge.settings.bus.style.fontSize" :disabled="!state.editing" label="Font Size" input-id="bus-font-size" placeholder="1rem"/>
                            <option-select v-model="selected.badge.settings.bus.style.fontWeight" :disabled="!state.editing" label="Font Weight" input-id="bus-font-weight" :options="options.fontWeight" placeholder="normal"/>
                          </b-card-body>
                        </b-collapse>

                        <!-- Padding Options -->
                        <options-header v-b-toggle.options-bus-padding header-class="options-header" title="Padding"/>
                        <b-collapse id="options-bus-padding" role="tabpanel" class="options-collapse">
                          <b-card-body body-bg-variant="white">
                            <options-sync-container :disabled="!state.editing">
                              <option-value-unit v-model="selected.badge.settings.bus.style.paddingTop" :disabled="!state.editing" label="Top" input-id="bus-padding-top" placeholder="unset"/>
                              <option-value-unit v-model="selected.badge.settings.bus.style.paddingBottom" :disabled="!state.editing" label="Bottom" input-id="bus-padding-bottom" placeholder="unset"/>
                              <option-value-unit v-model="selected.badge.settings.bus.style.paddingLeft" :disabled="!state.editing" label="Left" input-id="bus-padding-left" placeholder="unset"/>
                              <option-value-unit v-model="selected.badge.settings.bus.style.paddingRight" :disabled="!state.editing" label="Right" input-id="bus-padding-right" placeholder="unset"/>
                            </options-sync-container>
                          </b-card-body>
                        </b-collapse>

                        <!-- Margin Options -->
                        <options-header v-b-toggle.options-bus-margin header-class="options-header" title="Margin"/>
                        <b-collapse id="options-bus-margin" role="tabpanel" class="options-collapse">
                          <b-card-body body-bg-variant="white">
                            <options-sync-container :disabled="!state.editing">
                              <option-value-unit v-model="selected.badge.settings.bus.style.marginTop" :disabled="!state.editing" label="Top" input-id="bus-margin-top" placeholder="0rem"/>
                              <option-value-unit v-model="selected.badge.settings.bus.style.marginBottom" :disabled="!state.editing" label="Bottom" input-id="bus-margin-bottom" placeholder="0.5rem"/>
                              <option-value-unit v-model="selected.badge.settings.bus.style.marginLeft" :disabled="!state.editing" label="Left" input-id="bus-margin-left" placeholder="0.5rem"/>
                              <option-value-unit v-model="selected.badge.settings.bus.style.marginRight" :disabled="!state.editing" label="Right" input-id="bus-margin-right" placeholder="0.5rem"/>
                            </options-sync-container>
                          </b-card-body>
                        </b-collapse>

                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </template>
                <!-- Footer -->
                <b-card no-body class="mb-0">
                  <options-header v-b-toggle.options-footer v-b-hover="hoverFooter" header-class="options-header" title="Footer"></options-header>
                  <b-collapse id="options-footer" role="tabpanel" class="options-collapse">
                    <b-card-body body-bg-variant="light-primary">

                      <!-- Text Options -->
                      <options-header v-b-toggle.options-footer-text header-class="options-header" title="Text"></options-header>
                      <b-collapse id="options-footer-text" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <b-textarea v-model="selected.badge.settings.footer.text" :disabled="!state.editing" no-resize rows="2" max-rows="2" class="font-small-3"/>
                        </b-card-body>
                      </b-collapse>

                      <!-- Background Options -->
                      <options-header v-b-toggle.options-footer-background header-class="options-header" title="Background"></options-header>
                      <b-collapse id="options-footer-background" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <option-color v-model="selected.badge.settings.footer.style.background" :disabled="!state.editing" label="Background Color" input-id="footer-background"/>
                        </b-card-body>
                      </b-collapse>

                      <!-- Font Options -->
                      <options-header v-b-toggle.options-footer-font header-class="options-header" title="Font"></options-header>
                      <b-collapse id="options-footer-font" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <option-color v-model="selected.badge.settings.footer.style.color" :disabled="!state.editing" label="Font Color" input-id="footer-color"/>
                          <option-value-unit v-model="selected.badge.settings.footer.style.fontSize" :disabled="!state.editing" label="Font Size" input-id="footer-font-size" placeholder="1rem"/>
                          <option-select v-model="selected.badge.settings.footer.style.fontWeight" :disabled="!state.editing" label="Font Weight" input-id="footer-font-weight" :options="options.fontWeight" placeholder="bold"/>
                        </b-card-body>
                      </b-collapse>

                      <!-- Padding Options -->
                      <options-header v-b-toggle.options-footer-padding header-class="options-header" title="Padding"></options-header>
                      <b-collapse id="options-footer-padding" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <options-sync-container :disabled="!state.editing">
                            <option-value-unit v-model="selected.badge.settings.footer.style.paddingTop" :disabled="!state.editing" label="Top" input-id="footer-padding-top" placeholder="0.5rem"/>
                            <option-value-unit v-model="selected.badge.settings.footer.style.paddingBottom" :disabled="!state.editing" label="Bottom" input-id="footer-padding-bottom" placeholder="0.5rem"/>
                            <option-value-unit v-model="selected.badge.settings.footer.style.paddingLeft" :disabled="!state.editing" label="Left" input-id="footer-padding-left" placeholder="0.5rem"/>
                            <option-value-unit v-model="selected.badge.settings.footer.style.paddingRight" :disabled="!state.editing" label="Right" input-id="footer-padding-right" placeholder="0.5rem"/>
                          </options-sync-container>
                        </b-card-body>
                      </b-collapse>

                      <!-- Margin Options -->
                      <options-header v-b-toggle.options-footer-margin header-class="options-header" title="Margin"></options-header>
                      <b-collapse id="options-footer-margin" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <options-sync-container :disabled="!state.editing">
                            <option-value-unit v-model="selected.badge.settings.footer.style.marginBottom" :disabled="!state.editing" label="Bottom" input-id="footer-margin-bottom" placeholder="unset"/>
                            <option-value-unit v-model="selected.badge.settings.footer.style.marginLeft" :disabled="!state.editing" label="Left" input-id="footer-margin-left" placeholder="unset"/>
                            <option-value-unit v-model="selected.badge.settings.footer.style.marginRight" :disabled="!state.editing" label="Right" input-id="footer-margin-right" placeholder="unset"/>
                          </options-sync-container>
                        </b-card-body>
                      </b-collapse>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </settings-container>
            </can>
          </template>
          <!-- region Alerts -->
          <b-alert :show="hasChanges" variant="danger" class="d-print-none">
            <div class="d-flex">
              <b-icon icon="exclamation-triangle-fill" font-scale="1" class="mt-25 mr-25"/>
              <div class="align-start ml-50 font-small-3">
                <strong class="d-block">Unsaved Changed!</strong>
                <span>Don't forget to save your changes before leaving the page.</span>
              </div>
            </div>
          </b-alert>
          <b-alert :show="isContentScaled && !alerts.contentScaled.dismissed" variant="primary" class="d-print-none" dismissible @dismissed="alerts.contentScaled.dismissed = true">
            <b-icon icon="exclamation-triangle-fill" font-scale="1"/>
            <span class="align-middle ml-50 font-small-3">
                  The content has been scaled down to fit the page.
                </span>
          </b-alert>
          <b-alert :show="state.editing && !alerts.editing.dismissed" variant="primary" class="d-print-none" dismissible @dismissed="alerts.editing.dismissed = true">
            <b-icon-info-circle-fill font-scale="1"/> <strong class="align-middle ml-50 font-small-3">Edit Mode</strong>
            <p class="align-middle font-small-3">
              While in edit mode, configurable areas are outlined with dashed borders for guidance.
              These borders will not appear on the final printed badges, and the editable preview will
              differ slightly from the printed version.
            </p>
          </b-alert>
          <!-- endregion Alerts -->
        </template>
      </event-layout>
		</template>

		<template #debug>
      <b-row>
        <b-col cols="4">
          <debug title="Selected">
            {{ selected }}
          </debug>
        </b-col>
        <b-col cols="4">
          <debug title="Badge Settings">
            {{ selected.badge ? selected.badge.settings : null }}
          </debug>
        </b-col>
        <b-col cols="4">
          <debug title="Badge Initial">
            {{ selected.badge ? selected.badge.initial : null }}
          </debug>
        </b-col>
        <b-col cols="4">
          <debug title="Filters">
            {{ filters }}
          </debug>
        </b-col>
        <b-col cols="4">
          <debug title="Selections">
            {{ selections }}
          </debug>
        </b-col>
      </b-row>

		</template>
	</page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import vSelect from 'vue-select'
import Fuse from 'fuse.js';
import {API, graphqlOperation} from 'aws-amplify';
import {listSelections} from '@/views/events/badges/badges';
import _ from 'lodash';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import Badge from '@/views/events/badges/Badge.vue';
import SettingsContainer from '@/views/printable/settings/SettingsContainer.vue';
import OptionsSyncContainer from '@/views/printable/settings/OptionsSyncContainer.vue';
import OptionsHeader from '@/views/printable/settings/OptionsHeader.vue';
import OptionColor from '@/views/printable/settings/OptionColor.vue';
import OptionImage from '@/views/printable/settings/OptionImage.vue';
import OptionSelect from '@/views/printable/settings/OptionSelect.vue';
import OptionValueUnit from '@/views/printable/settings/OptionValueUnit.vue';

import printMixin from '@/views/printable/print.mixin';
import styleMixin from '@/mixins/style.mixin';
import printableSettingsMixin from '@/views/printable/settings/settings.mixin';
import OptionEnabled from '@/views/printable/settings/OptionEnabled.vue';
import OptionValue from '@/views/printable/settings/OptionValue.vue';
import lodashMixin from '@/mixins/lodash.mixin';
import settingsMixin from '@/mixins/settings.mixin';
import draggable from 'vuedraggable';
import SortInput from '@/components/SortInput.vue';
import AssignedStudents from '@/views/events/buses/AssignedStudents.vue';
import UnassignedStudents from '@/views/events/buses/UnassignedStudents.vue';
import EventsNav from '@/views/events/EventsNav.vue';
import EventLayout from '@/views/events/EventLayout.vue';

export default {
  name: 'Badges',
	components: {
    EventLayout,
    EventsNav,
    UnassignedStudents,
    AssignedStudents,
    SortInput,
    OptionValue,
    OptionEnabled,
		Badge,
		SettingsContainer,
		PageLayout,
		OptionSelect,
		OptionValueUnit,
		OptionColor,
		OptionImage,
		OptionsHeader,
		OptionsSyncContainer,
		BCardActions,
		vSelect,
    draggable
	},
	mixins: [ printableSettingsMixin, styleMixin, printMixin, lodashMixin, settingsMixin],
  props: {
    id: {
      type: String,
      default: null
    }
  },
	data() {
		return {
      selected: {
        badge: null,
        loading: false,
      },
      badges: {
        loading: false,
        items: [
          {
            id: 'albany',
            key: 'badges-albany',
            name: 'Albany',
            loaded: false,
            settings: {
              container: {
                style: {
                  paddingTop: {
                    value: null,
                    unit: null
                  },
                  paddingBottom: {
                    value: null,
                    unit: null
                  },
                  paddingLeft: {
                    value: null,
                    unit: null
                  },
                  paddingRight: {
                    value: null,
                    unit: null
                  },
                  gridColumnGap: {
                    value: null,
                    unit: null
                  },
                  gridRowGap: {
                    value: null,
                    unit: null
                  }
                }
              },
              badge: {
                style: {
                  color: null,
                  fontFamily: null,
                  fontWeight: null,
                  borderColor: null,
                  borderStyle: null,
                  borderWidth: {
                    value: null,
                    unit: null
                  },
                  width: null,
                  height: null,
                  margin: {
                    value: null,
                    unit: null
                  },
                  paddingTop: {
                    value: null,
                    unit: null
                  },
                  paddingBottom: {
                    value: null,
                    unit: null
                  },
                  paddingLeft: {
                    value: null,
                    unit: null
                  },
                  paddingRight: {
                    value: null,
                    unit: null
                  },
                },
              },
              body: {
                style: {
                  borderColor: null,
                  borderStyle: null,
                  borderWidth: {
                    value: null,
                    unit: null
                  },
                  paddingTop: {
                    value: null,
                    unit: null
                  },
                  paddingBottom: {
                    value: null,
                    unit: null
                  },
                  paddingLeft: {
                    value: null,
                    unit: null
                  },
                  paddingRight: {
                    value: null,
                    unit: null
                  },
                }
              },
              logo: {
                src: null,
                style: {
                  width: {
                    value: null,
                    unit: null
                  },
                  height: {
                    value: null,
                    unit: null
                  },
                  borderWidth: {
                    value: null,
                    unit: null
                  },
                  borderStyle: null,
                  borderColor: null,
                  borderRadius: null,
                  objectFit: null,
                  marginTop: {
                    value: null,
                    unit: null
                  },
                  marginBottom: {
                    value: null,
                    unit: null
                  },
                }
              },
              student: {
                short: {
                  enabled: true,
                  format: null,
                  style: {
                    fontSize: {
                      value: null,
                      unit: null
                    },
                    fontWeight: null,
                    marginTop: {
                      value: null,
                      unit: null
                    },
                    marginBottom: {
                      value: null,
                      unit: null
                    },
                    marginLeft: {
                      value: null,
                      unit: null
                    },
                    marginRight: {
                      value: null,
                      unit: null
                    },
                    paddingTop: {
                      value: null,
                      unit: null
                    },
                    paddingBottom: {
                      value: null,
                      unit: null
                    },
                    paddingLeft: {
                      value: null,
                      unit: null
                    },
                    paddingRight: {
                      value: null,
                      unit: null
                    },
                  }
                },
                full: {
                  enabled: true,
                  format: null,
                  style: {
                    fontSize: {
                      value: null,
                      unit: null
                    },
                    fontWeight: null,
                    marginTop: {
                      value: null,
                      unit: null
                    },
                    marginBottom: {
                      value: null,
                      unit: null
                    },
                    marginLeft: {
                      value: null,
                      unit: null
                    },
                    marginRight: {
                      value: null,
                      unit: null
                    },
                    paddingTop: {
                      value: null,
                      unit: null
                    },
                    paddingBottom: {
                      value: null,
                      unit: null
                    },
                    paddingLeft: {
                      value: null,
                      unit: null
                    },
                    paddingRight: {
                      value: null,
                      unit: null
                    },
                  }
                },
              },
              ensemble: {
                enabled: true,
                text: '',
                style: {
                  fontSize: {
                    value: null,
                    unit: null
                  },
                  fontWeight: null,
                  marginTop: {
                    value: null,
                    unit: null
                  },
                  marginBottom: {
                    value: null,
                    unit: null
                  },
                  marginLeft: {
                    value: null,
                    unit: null
                  },
                  marginRight: {
                    value: null,
                    unit: null
                  },
                  paddingTop: {
                    value: null,
                    unit: null
                  },
                  paddingBottom: {
                    value: null,
                    unit: null
                  },
                  paddingLeft: {
                    value: null,
                    unit: null
                  },
                  paddingRight: {
                    value: null,
                    unit: null
                  },
                }
              },
              bus: {
                enabled: false,
              },
              footer: {
                text: `All-State ${new Date().getFullYear()}`,
                style: {
                  background: null,
                  color: null,
                  display: null,
                  fontSize: {
                    value: null,
                    unit: null
                  },
                  fontWeight: null,
                  justifyContent: null,
                  marginBottom: {
                    value: null,
                    unit: null
                  },
                  marginLeft: {
                    value: null,
                    unit: null
                  },
                  marginRight: {
                    value: null,
                    unit: null
                  },
                  paddingTop: {
                    value: null,
                    unit: null
                  },
                  paddingBottom: {
                    value: null,
                    unit: null
                  },
                  paddingLeft: {
                    value: null,
                    unit: null
                  },
                  paddingRight: {
                    value: null,
                    unit: null
                  },
                },
              },
            },
            initial: null,
          },
          {
            id: 'conference',
            key: 'badges-conference',
            name: 'Conference',
            loaded: false,
            settings: {
              container: {
                style: {
                  paddingTop: {
                    value: null,
                    unit: null
                  },
                  paddingBottom: {
                    value: null,
                    unit: null
                  },
                  paddingLeft: {
                    value: null,
                    unit: null
                  },
                  paddingRight: {
                    value: null,
                    unit: null
                  },
                  gridColumnGap: {
                    value: null,
                    unit: null
                  },
                  gridRowGap: {
                    value: null,
                    unit: null
                  }
                }
              },
              badge: {
                style: {
                  color: null,
                  fontFamily: null,
                  fontWeight: null,
                  borderColor: null,
                  borderStyle: null,
                  borderWidth: {
                    value: null,
                    unit: null
                  },
                  width: null,
                  height: null,
                  margin: {
                    value: null,
                    unit: null
                  },
                  paddingTop: {
                    value: null,
                    unit: null
                  },
                  paddingBottom: {
                    value: null,
                    unit: null
                  },
                  paddingLeft: {
                    value: null,
                    unit: null
                  },
                  paddingRight: {
                    value: null,
                    unit: null
                  },
                },
              },
              body: {
                style: {
                  borderColor: null,
                  borderStyle: null,
                  borderWidth: {
                    value: null,
                    unit: null
                  },
                  paddingTop: {
                    value: null,
                    unit: null
                  },
                  paddingBottom: {
                    value: null,
                    unit: null
                  },
                  paddingLeft: {
                    value: null,
                    unit: null
                  },
                  paddingRight: {
                    value: null,
                    unit: null
                  },
                }
              },
              logo: {
                src: null,
                style: {
                  width: {
                    value: null,
                    unit: null
                  },
                  height: {
                    value: null,
                    unit: null
                  },
                  borderWidth: {
                    value: null,
                    unit: null
                  },
                  borderStyle: null,
                  borderColor: null,
                  borderRadius: null,
                  objectFit: null,
                  marginTop: {
                    value: null,
                    unit: null
                  },
                  marginBottom: {
                    value: null,
                    unit: null
                  },
                }
              },
              student: {
                short: {
                  enabled: true,
                  format: null,
                  style: {
                    fontSize: {
                      value: null,
                      unit: null
                    },
                    fontWeight: null,
                    marginTop: {
                      value: null,
                      unit: null
                    },
                    marginBottom: {
                      value: null,
                      unit: null
                    },
                    marginLeft: {
                      value: null,
                      unit: null
                    },
                    marginRight: {
                      value: null,
                      unit: null
                    },
                    paddingTop: {
                      value: null,
                      unit: null
                    },
                    paddingBottom: {
                      value: null,
                      unit: null
                    },
                    paddingLeft: {
                      value: null,
                      unit: null
                    },
                    paddingRight: {
                      value: null,
                      unit: null
                    },
                  }
                },
                full: {
                  enabled: true,
                  format: null,
                  style: {
                    fontSize: {
                      value: null,
                      unit: null
                    },
                    fontWeight: null,
                    marginTop: {
                      value: null,
                      unit: null
                    },
                    marginBottom: {
                      value: null,
                      unit: null
                    },
                    marginLeft: {
                      value: null,
                      unit: null
                    },
                    marginRight: {
                      value: null,
                      unit: null
                    },
                    paddingTop: {
                      value: null,
                      unit: null
                    },
                    paddingBottom: {
                      value: null,
                      unit: null
                    },
                    paddingLeft: {
                      value: null,
                      unit: null
                    },
                    paddingRight: {
                      value: null,
                      unit: null
                    },
                  }
                },
              },
              /*school: {
                textFormat: '',
                style: {
                  fontSize: {
                    value: null,
                    unit: null
                  },
                  fontWeight: null,
                  marginTop: {
                    value: null,
                    unit: null
                  },
                  marginBottom: {
                    value: null,
                    unit: null
                  },
                  marginLeft: {
                    value: null,
                    unit: null
                  },
                  marginRight: {
                    value: null,
                    unit: null
                  },
                  paddingTop: {
                    value: null,
                    unit: null
                  },
                  paddingBottom: {
                    value: null,
                    unit: null
                  },
                  paddingLeft: {
                    value: null,
                    unit: null
                  },
                  paddingRight: {
                    value: null,
                    unit: null
                  },
                }
              },*/
              ensemble: {
                enabled: true,
                text: '',
                style: {
                  fontSize: {
                    value: null,
                    unit: null
                  },
                  fontWeight: null,
                  marginTop: {
                    value: null,
                    unit: null
                  },
                  marginBottom: {
                    value: null,
                    unit: null
                  },
                  marginLeft: {
                    value: null,
                    unit: null
                  },
                  marginRight: {
                    value: null,
                    unit: null
                  },
                  paddingTop: {
                    value: null,
                    unit: null
                  },
                  paddingBottom: {
                    value: null,
                    unit: null
                  },
                  paddingLeft: {
                    value: null,
                    unit: null
                  },
                  paddingRight: {
                    value: null,
                    unit: null
                  },
                }
              },
              bus: {
                enabled: true,
                text: '',
                textFormat: null,
                style: {
                  fontSize: {
                    value: null,
                    unit: null
                  },
                  fontWeight: null,
                  marginTop: {
                    value: null,
                    unit: null
                  },
                  marginBottom: {
                    value: null,
                    unit: null
                  },
                  marginLeft: {
                    value: null,
                    unit: null
                  },
                  marginRight: {
                    value: null,
                    unit: null
                  },
                  paddingTop: {
                    value: null,
                    unit: null
                  },
                  paddingBottom: {
                    value: null,
                    unit: null
                  },
                  paddingLeft: {
                    value: null,
                    unit: null
                  },
                  paddingRight: {
                    value: null,
                    unit: null
                  },
                }
              },
              footer: {
                text: `All-State ${new Date().getFullYear()}`,
                style: {
                  background: null,
                  color: null,
                  display: null,
                  fontSize: {
                    value: null,
                    unit: null
                  },
                  fontWeight: null,
                  justifyContent: null,
                  marginBottom: {
                    value: null,
                    unit: null
                  },
                  marginLeft: {
                    value: null,
                    unit: null
                  },
                  marginRight: {
                    value: null,
                    unit: null
                  },
                  paddingTop: {
                    value: null,
                    unit: null
                  },
                  paddingBottom: {
                    value: null,
                    unit: null
                  },
                  paddingLeft: {
                    value: null,
                    unit: null
                  },
                  paddingRight: {
                    value: null,
                    unit: null
                  },
                },
              },
            },
            initial: null,
          },
        ],
        search: null,
        filters: null,
        sorting: {
          by: ['name'],
          desc: false,
          drag: false,
          options: [
            { label: 'Name', value: 'name'},
          ]
        },
      },
			options: {
        showBlanks: false,
        student: {
          short: {
            textFormat: [
              { value: 'name.first', label: 'First Name' },
              { value: 'name.preferred', label: 'Preferred Name' },
              { value: 'name.default', label: 'Preferred Name or First Name' },
            ]
          },
          full: {
            textFormat: [
              { value: 'name.firstLast', label: 'First, Last' },
              { value: 'name.firstPreferredLast', label: 'First, Preferred, Last' },
            ]
          }
        },
        bus: {
          textFormat: [
            { value: 'bus.name', label: 'Bus Name' },
            { value: 'bus.number', label: 'Bus Number' },
          ]
        }
      },
      sidebar: true,
			filters: {
        visible: false,
        collapsed: false,
				schools: {
					value: null,
					items: [],
					loading: true
				},
				ensembles: {
					value: null,
          values: [],
					items: [],
					loading: true
				},
				instruments: {
					value: null,
					items: [],
					loading: true
				},
				students: {
					value: null,
					items: [],
					loading: true
				},
				teachers: {
					value: null,
					items: [],
					loading: true
				},
        buses: {
          value: null,
          items: [],
          loading: true
        },
			},
      sorting: {
        collapsed: false,
        by: ['ensemble.name', 'application.student.name.last', 'application.student.name.first'],
        desc: false,
        drag: false,
        options: [
          { label: 'Ensemble', value: 'ensemble.name'},
          { label: 'First Name', value: 'application.student.name.first'},
          { label: 'Last Name', value: 'application.student.name.last'},
        ]
      },
      paging: {
        total: 0,
        page: 1,
        size: 1,
        sizes: [
          { value: -1, label: 'All' },
          { value: 1, label: '1' },
          { value: 5, label: '5' },
          { value: 10, label: '10' },
          { value: 25, label: '25' },
          { value: 50, label: '50' },
          { value: 100, label: '100' }
        ]
      },
      alerts: {
        editing: {
          dismissed: false
        },
        contentScaled: {
          dismissed: false
        },
      },
      hover: {
        container: false,
        badge: false,
        logo: false,
        studentShort: false,
        studentFull: false,
        ensemble: false,
        bus: false,
        footer: false
      },
      selections: {
        items: [],
        loading: true,
      },
      /* eslint-disable global-require */
      logo: require('@/assets/images/logo/logo5-blue-white-gold.svg'),
      image: require('@/assets/images/illustration/storyset-id-card-rafiki.svg'),
		};
	},
	computed: {
		filteredSelections() {
      const keys = []
      const query = {$and: []}
      if (this.filters.ensembles.values.length) {
        keys.push('ensemble.id')
        const or = []
        this.filters.ensembles.values.forEach(ensemble => {
          or.push({'ensemble.id': ensemble})
        })
        query.$and.push({$or: or})
      }
      if (this.filters.instruments.value) {
        keys.push('application.instrument.id')
        query.$and.push({'application.instrument.id': this.filters.instruments.value})
      }
      if (this.filters.students.value) {
        keys.push('application.student.id')
        query.$and.push({'application.student.id': this.filters.students.value})
      }
      if (this.filters.schools.value) {
        keys.push('application.student.school.id')
        query.$and.push({'application.student.school.id': this.filters.schools.value})
      }
      if (this.filters.buses.value && this.filters.buses.value !== 'undefined') {
        keys.push('application.student.event.busId')
        query.$and.push({'application.student.event.busId': this.filters.buses.value})
      }
      if (this.filters.teachers.value) {
        keys.push('application.teacher.id')
        query.$and.push({'application.teacher.id': this.filters.teachers.value})
      }

			const fuse = new Fuse(this.selections.items, {
				useExtendedSearch: true,
				threshold: 0.3,
				keys: keys
			})

			let {items} = this.selections
			if (query.$and.length) {
				items = fuse.search(query).map(({item}) => item)
			}

      if(this.filters.buses.value === 'undefined') {
        items = items.filter(({application}) => application.student.bus === null)
      }

      items = this.sortItems(items)

      if(this.paging.size >= 1) {
        this.paging.total = Math.ceil(items.length / 6) // eslint-disable-line vue/no-side-effects-in-computed-properties
        const chunks = _.chunk(items, Math.ceil(this.paging.size * 6))
        return chunks[this.paging.page - 1] || []
      }
      return items
		},
    pagination() {
      let from = 0
      if(this.paging.total > 0) {
        //from = 6 * (this.paging.page - 1) + 1
        from = (this.paging.size * (6 * (this.paging.page - 1)) + 1)
      }

      let to = (this.paging.size * this.paging.page) * 6
      if(this.paging.size <= 0 || to >= this.selections.items.length) {
        to = this.selections.items.length
      }

      return {
        from: from,
        to: to,
        of: this.selections.items.length,
      }
    },
    missingBusCount() {
      return this.filteredSelections.filter(({application}) => application.student.bus === null).length
    },
    hasChanges() {
      return JSON.stringify(this.selected?.badge?.initial) !== JSON.stringify(this.selected?.badge?.settings)
    }
	},
	async mounted() {
		this.updateContentScaleWidth(1366) // print.mixin method
		await this.listSelections()
    await this.$nextTick(() => {
      if(this.id) this.selectBadge(this.badges.items.find(badge => badge.id === this.id))
      else {
        this.selected.badge = null
      }
      setTimeout( () => { this.$refs.layout.state.loading = false }, 500);
    })
	},
	methods: {
    async selectBadge(badge) {
      this.selected = { ...this.selected, loading: true, badge: { settings: null, initial: null } };
      if(!badge) {
        await this.$router.push({ name: 'events-badges', params: { id: null } })
      }
      else {
        if(!badge?.loaded) {
          const settings = await this.getSettings(badge.key)
          if(settings) {
            this.deepMerge(badge.settings, settings);
            if(badge?.settings?.logo?.src === null) {
              badge.logo.src = this.logo;
            }
            //badge.initial = JSON.parse(JSON.stringify(badge.settings));
            badge.initial = _.cloneDeep(badge.settings)
          }
          badge.loaded = true
        }

        this.selected.badge = badge;
        if(this.$route.params.id !== badge.id) {
          await this.$router.push({ name: 'events-badge', params: { id: badge.id } })
        }
      }
      setTimeout(() => { this.selected.loading = false }, 500)
    },
    async deselectBadge() {
      this.selected.badge = null
      this.selected.loading = false
      if(this.$route.params.id !== null) {
        await this.$router.push({ name: 'events-badges', params: { id: null } })
      }
    },
    updateSelectedBadgeInitial() {
      this.selected.badge.initial = _.cloneDeep(this.selected.badge.settings);
    },
    resetSelectedBadgeSettings() {
      this.selected.badge.settings = _.cloneDeep(this.selected.badge.initial);
    },


    /** API **/
		refresh() {
			this.selections.loading = true
      this.selections.items = []

      this.filters.buses.loading = true
      this.filters.buses.items = []

      this.filters.ensembles.loading = true
      this.filters.ensembles.items = []

      this.filters.instruments.loading = true
      this.filters.instruments.items = []

      this.filters.schools.loading = true
      this.filters.schools.items = []

      this.filters.students.loading = true
      this.filters.students.items = []

      this.filters.teachers.loading = true
      this.filters.teachers.items = []

			this.listSelections()
		},
		async listSelections(nextToken, pagedSelections) {
			const selections = pagedSelections || []
      const input = {
        limit: 500,
        filter: {
          accepted: { eq: true },
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        },
        nextToken: nextToken,
      }
			const response = await API.graphql(graphqlOperation(listSelections, input));

			selections.push(...response.data.listSelections.items);

			if(response.data.listSelections.nextToken) {
				await this.listSelections(response.data.listSelections.nextToken, selections)
			}
			else {
				this.selections.items = selections.filter(selection => selection?.ensemble?.code !== 'A');
				this.selections.loading = false

        /** Schools Filter **/
        this.filters.schools.items = _.uniqBy(this.selections.items.filter(item => item.application?.student?.school?.id).map(item => ({
          value: item.application?.student?.school?.id,
          label: item.application?.student?.school?.name?.legal
        })), 'value').sort((a, b) => a.label.localeCompare(b.label));
        if(!this.filters.schools.items.some(item => item.value === this.filters.schools.value)) {
          this.filters.schools.value = null
        }
        this.filters.schools.loading = false

        /** Ensembles Filter **/
        this.filters.ensembles.items = _.uniqBy(this.selections.items.filter(item => item.ensemble?.id).map(item => ({
          value: item.ensemble?.id,
          label: item.ensemble.name
        })), 'value').sort((a, b) => a.label.localeCompare(b.label));
        if(!this.filters.ensembles.items.some(item => this.filters.ensembles.values.includes(item.value))) {
          this.filters.ensembles.values = []
        }
        this.filters.ensembles.loading = false

        /** Instruments Filter **/
        this.filters.instruments.items = _.uniqBy(this.selections.items.filter(item => item.application?.instrument?.id).map(item => ({
          value: item.application?.instrument?.id,
          label: item.application?.instrument.name
        })), 'value').sort((a, b) => a.label.localeCompare(b.label));
        if(!this.filters.instruments.items.some(item => item.value === this.filters.instruments.value)) {
          this.filters.instruments.value = null
        }
        this.filters.instruments.loading = false

        /** Students Filter **/
        this.filters.students.items = _.uniqBy(this.selections.items.filter(item => item.application?.student?.id).map(item => ({
          value: item.application?.student?.id,
          label: `${item.application?.student?.name?.first} ${item.application?.student?.name?.last}`
        })), 'value').sort((a, b) => a.label.localeCompare(b.label));
        if(!this.filters.students.items.some(item => item.value === this.filters.students.value)) {
          this.filters.students.value = null
        }
        this.filters.students.loading = false

        /** Teachers Filter **/
        this.filters.teachers.items = _.uniqBy(this.selections.items.filter(item => item.application?.teacher?.id).map(item => ({
          value: item.application?.teacher?.id,
          label: `${item.application?.teacher?.name?.first} ${item.application?.teacher?.name?.last}`
        })), 'value').sort((a, b) => a.label.localeCompare(b.label));
        if(!this.filters.teachers.items.some(item => item.value === this.filters.teachers.value)) {
          this.filters.teachers.value = null
        }
        this.filters.teachers.loading = false

        /** Buses Filter **/
        this.filters.buses.items = _.uniqBy(this.selections.items.filter(item => item.application?.student?.event?.busId).map(item => ({
          value: item.application?.student?.event?.busId ? item.application?.student?.event?.busId : 'undefined',
          label: `${item.application?.student?.event.bus?.name}`
        })), 'value').sort((a, b) => a.label.localeCompare(b.label));
        if(!this.filters.buses.items.some(item => item.value === this.filters.buses.value)) {
          this.filters.buses.value = null
        }
        this.filters.buses.loading = false
			}
		},

    /** Hover **/
    hoverContainer(isHovered) {
      this.hover.container = isHovered
    },
    hoverBadge(isHovered) {
      this.hover.badge = isHovered
    },
    hoverLogo(isHovered) {
      this.hover.logo = isHovered
    },
    hoverStudentShort(isHovered) {
      this.hover.studentShort = isHovered
    },
    hoverStudentFull(isHovered) {
      this.hover.studentFull = isHovered
    },
    hoverEnsemble(isHovered) {
      this.hover.ensemble = isHovered
    },
    hoverBus(isHovered) {
      this.hover.bus = isHovered
    },
    hoverFooter(isHovered) {
      this.hover.footer = isHovered
    },

    /** Sort **/
    sortItems(items) {
      const { by, desc } = this.sorting;
      const order = desc ? 'desc' : 'asc';
      const orders = []; // Array to hold the order (asc/desc) for each criteria

      for (let i = 0; i < by.length; i++) {
        orders.push(order);
      }

      return _.orderBy(items, by, orders);
    },
	}
};
</script>

<style>
@import './badges.css';
</style>

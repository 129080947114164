<template>
  <b-card-header class="options-header" header-tag="header" role="tab" :title="isEnabled ? 'Enabled' : 'Disabled'">
    <b-button variant="transparent" class="px-0 text-left font-small-3" :disabled="disabled" @click="toggle">{{ text }}</b-button>
    <b-button id="bus-enabled"
              v-model="isEnabled"
              :disabled="disabled"
              size="sm" button variant="transparent" class="p-0" @click="toggle">
      <feather-icon :icon="isEnabled ? 'CheckCircleIcon' : 'CircleIcon'" />
    </b-button>
  </b-card-header>
</template>

<script>
import OptionValueUnit from '@/views/printable/settings/OptionValueUnit.vue';

export default {
  components: {OptionValueUnit},
  props: {
    text: {
      type: String,
      default: 'Enabled',
    },
    enabled: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEnabled: this.enabled,
    };
  },
  methods: {
    toggle() {
      this.isEnabled = !this.isEnabled;
      this.$emit('update:enabled', this.isEnabled);
    },
  },
};
</script>

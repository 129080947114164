<template>
  <div class="name-badge"
       :class="isHovering(hoverBadge)"
       :style="computedStyle(settings.badge.style)">

    <img v-if="includeLogo"
         :src="settings.logo.src"
         class="name-badge-logo" alt="Logo"
         :class="isHovering(hoverLogo)"
         :style="computedStyle(settings.logo.style)"
    />
    <div v-if="includeStudentShortName"
         class="name-badge-student-name-short"
         :class="[isHovering(hoverStudentShortName), blanksClass]"
         :style="computedStyle(settings.student.short.style)">


      <!-- Student Short Name -->
      <template v-if="isBlanks">
        <div :contenteditable="isEditable" @input="(event) => $emit('input:name-short', event.target.innerText)"></div>
      </template>
      <template v-else>
        <template v-if="studentNameShort && typeof studentNameShort === 'object'">
          <template v-if="settings.student.short.textFormat === 'name.first'">
            {{ studentNameShort.first }}
          </template>
          <template v-else-if="settings.student.short.textFormat === 'name.preferred'">
            {{ studentNameShort.preferred }}
          </template>
          <template v-else>
            {{ studentNameShort.preferred ? studentNameShort.preferred : studentNameShort.first }}
          </template>
        </template>
        <template v-else-if="studentNameShort && typeof studentNameShort === 'string'">
          {{ studentNameShort }}
        </template>
        <template v-else>
          <span class="text-danger">No Short Name</span>
        </template>
      </template>
    </div>

    <!-- Student Full Name -->
    <div v-if="includeStudentFullName"
         class="name-badge-student-name-full"
         :class="[isHovering(hoverStudentFullName), blanksClass]"
         :style="computedStyle(settings.student.full.style)">

      <template v-if="isBlanks">
        <div :contenteditable="isEditable" @input="(event) => $emit('input:name-full', event.target.innerText)"></div>
      </template>
      <template v-else>
        <template v-if="studentNameFull && typeof studentNameFull === 'object'">
          <template v-if="settings.student.full.textFormat === 'name.firstPreferredLast'">
            {{ studentNameFull.first }} "{{ studentNameFull.preferred }}" {{ studentNameFull.last }}
          </template>
          <template v-else>
            {{ studentNameFull.first }} {{ studentNameFull.last }}
          </template>
        </template>
        <template v-else-if="studentNameFull && typeof studentNameFull === 'string'">
          {{ studentNameFull }}
        </template>
        <template v-else>
          <span class="text-danger">No Full Name</span>
        </template>
      </template>

    </div>

    <!-- Ensemble -->
    <div v-if="includeEnsemble"
         class="name-badge-ensemble"
         :class="[isHovering(hoverEnsemble), blanksClass]"
         :style="computedStyle(settings.ensemble.style)">

      <template v-if="isBlanks">
        <div :contenteditable="isEditable" @input="(event) => $emit('input:ensemble', event.target.innerText)"></div>
      </template>
      <template v-else>
        <template v-if="ensemble">
          {{ ensemble }}
        </template>
        <template v-else>
          <span class="text-danger">No Ensemble</span>
        </template>
      </template>

    </div>

    <!-- Bus -->
    <div v-if="includeBus"
         class="name-badge-bus"
         :class="[isHovering(hoverBus), blanksClass]"
         :style="computedStyle(settings.bus.style)">

      <template v-if="isBlanks">
        <div :contenteditable="isEditable" @input="(event) => $emit('input:bus', event.target.innerText)"></div>
      </template>
      <template v-else>
        <template v-if="bus">
          <template v-if="settings.bus.textFormat === 'bus.number'">
            {{ settings.bus.text ? settings.bus.text : '' }} {{ bus.name }}
          </template>
          <template v-else>
            {{ settings.bus.text ? settings.bus.text : '' }} {{ bus.name }}
          </template>
        </template>
        <template v-else>
          <span class="text-danger">No Bus</span>
        </template>
      </template>

    </div>
    <div v-if="includeFooter"
         class="name-badge-footer"
         :class="isHovering(hoverFooter)"
         :style="computedStyle(settings.footer.style)">
      {{ settings.footer.text }}
    </div>
  </div>
</template>

<script>
import styleMixin from '@/mixins/style.mixin';

export default {
  name: 'Badge',
  mixins: [ styleMixin ],
  props: {
    settings: {
      type: Object,
      required: true
    },
    studentNameShort: {
      type: [Object, String],
      default: () => ({ first: '', last: '', preferred: '' })
    },
    studentNameFull: {
      type: [Object, String],
      default: () => ({ first: '', last: '', preferred: '' })
    },
    schoolName: {
      type: Object,
      default: () => ({ legal: '', popular: '' }),
    },
    ensemble: {
      type: String,
      default: 'Ensemble N/A',
    },
    bus: {
      type: Object,
      default: () => ({ id: null, name: 'No Bus' }),
    },
    isBlanks: {
      type: Boolean,
      default: false,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    includeLogo: {
      type: Boolean,
      default: true,
    },
    includeStudentShortName: {
      type: Boolean,
      default: true,
    },
    includeStudentFullName: {
      type: Boolean,
      default: true,
    },
    includeEnsemble: {
      type: Boolean,
      default: true,
    },
    includeBus: {
      type: Boolean,
      default: false,
    },
    includeFooter: {
      type: Boolean,
      default: true,
    },
    hoverBadge: {
      type: Boolean,
      default: false,
    },
    hoverLogo: {
      type: Boolean,
      default: false,
    },
    hoverStudent: {
      type: Boolean,
      default: false,
    },
    hoverStudentShortName: {
      type: Boolean,
      default: false,
    },
    hoverStudentFullName: {
      type: Boolean,
      default: false,
    },
    hoverEnsemble: {
      type: Boolean,
      default: false,
    },
    hoverBus: {
      type: Boolean,
      default: false,
    },
    hoverFooter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    blanksClass() {
      return this.isBlanks ? 'mx-1' : '';
    },
  },
  methods: {
    isHovering(prop) {
      return prop ? 'hover-border' : '';
    },
  },
}
</script>

<style scoped>
  div[contenteditable="true"]:focus-visible {
    outline: 2px solid var(--primary);
    border: 0;
  }

  div[contenteditable="false"]:empty:after {
    content: "\feff";
  }


  .name-badge {
    width: 100%;
    height: 100%;
    padding: 0;
    background: #fff;
    color: #000;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    page-break-inside: avoid;
    overflow: hidden;
    border-radius: 0;
  }

  .name-badge-editor, .name-badge-editor * {
    border: 1px dashed #ccc;
  }

  @media print {

    .name-badge {
      border-radius: 0!important;
    }
    .name-badge * {
      border: 0 !important;
      border-radius: 0;
    }
    .name-badge-editor, .name-badge-editor * {
      border: 0;
    }
  }

  .name-badge-logo {
    width: 1in;
    height: 1in;
    border-radius: 50%;
    object-fit: cover;
    margin: 1rem auto 1rem auto; /* Raise the avatar slightly */
    white-space: nowrap;
    background: #fff;
    color: #212969;
    position: relative;
    display: inline-flex;
    font-size: 1rem;
    vertical-align: middle;
    font-weight: 600;
    order: 0
  }

  .name-badge-student-name-short {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 .5rem .5rem .5rem;
  }

  .name-badge-student-name-full {
    font-size: 1rem;
    font-weight: bold;
    margin: 0 .5rem .5rem .5rem;
  }

  .name-badge-ensemble {
    font-size: 1em;
    margin: 0 .5rem .5rem .5rem;
  }
  .name-badge-bus {
    font-size: 1em;
    margin: 0 .5rem .5rem .5rem;
  }

  .name-badge-footer {
    font-size: 1em;
    font-weight: bold;
    padding: .5rem;
    /*border-radius: 0 0 0.358rem 0.358rem;*/
    background: #212969;
    color: #fff;
    margin-top: auto!important;
  }

</style>


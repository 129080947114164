// eslint-disable-next-line import/prefer-default-export
export const listSelections = /* GraphQL */ `
    query ListSelections(
        $id: ID
        $filter: ModelSelectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                ensemble {
                    id
                    name
                    code
                }
                application {
                    id
                    instrument {
                        id
                        name
                    }
                    teacher {
                        id
                        name {
                            first
                            last
                        }
                    }
                    student {
                        id
                        name {
                            first
                            last
                            preferred
                        }
                        school {
                            id
                            name {
                                legal
                            }
                        }
                        event {
                            id
                            busId
                            bus {
                                id
                                name
                            }
                        }
                    }
                }
            }
            nextToken
        }
    }
`;
